import mainimg from '../../../assets/assets-wowsummit/img/bgmain.webp';
import mainimg2 from '../../../assets/assets-wowsummit/img/bgmain.webp';
import logo from '../../../assets/assets-wowsummit/img/logo.svg';
import logoMob from '../../../assets/assets-wowsummit/img/logo_mobmob.svg';

import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { IconSvg } from 'src/components/common';
import Styles from '../../../components/booking/booking.module.scss';
import { AuthContext } from 'src/contexts';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useHttp, useNotification } from 'src/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from 'src/types/icons';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { UserInfoModal } from 'src/components/common/modal/UserInfoModal';
import { setToLocalStorage } from 'src/utils';
import { GuestsMenuCard } from './GuestesMenuCard';

interface Options {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}

interface Room {
  adults: number;
  children: { age: string; isValid: boolean }[];
}
type DateRange = [Date, Date];

type OutputType = {
  numOfRoom: number;
  numOfAdults: number;
  numOfChildren: number;
  childAges: number[];
};

const formatFullDate = (date: Date) => {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const weekday = date.toLocaleString('default', { weekday: 'short' });

  return `${weekday} ${day} ${month}`;
};
export default function Main() {
  const notify = useNotification();
  const [menu, setMenu] = useState(false);
  const [myTripUrl, setMyTripUrl] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { pathname } = useLocation();
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const navigate = useNavigate();

  const {
    logout,
    accessToken,
    profile,
    userAuth0,
    customLogoutHandler,
    appConfig,
    setAccessToken,
  } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState<DateRange>([today, tomorrow]);
  const [coordinates, setCoordinates] = useState<{ lat: number | null; lng: number | null }>({
    lat: null,
    lng: null,
  });
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();

  const [roomsData, setRoomsData] = useState<Room[]>([{ adults: 2, children: [] }]);

  const submitGuestHandler = (payload: Room[]) => {
    console.log({ payload });
    setRoomsData(payload);
  };

  const totalRooms = roomsData.length;
  const totalAdults = roomsData.reduce((sum, room) => sum + room.adults, 0);
  const totalChildren = roomsData.reduce((sum, room) => sum + room.children.length, 0);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const [inputValue, setInputValue] = useState('');
  const [selectedPlace, setSelectedPlace] = useState<any>(null);

  const { placesService, placePredictions, getPlacePredictions } = usePlacesAutocompleteService({
    apiKey: 'AIzaSyBh3w6t7z70lGIg9CJ403P_HSqWZiLrDrM',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlace(null);
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      setShowSuggestions(true);

      getPlacePredictions({ input: value });
    }
  };

  const handlePlaceSelect = (placeId: string) => {
    if (placesService) {
      placesService.getDetails({ placeId }, (place: any, status: any) => {
        setCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });

        // if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSelectedPlace(place);
        setInputValue(place.name || '');
        setShowSuggestions(false);
        // }
      });
    }
  };
  const handleInputFocus = () => {
    if (inputValue && placePredictions.length > 0) {
      setShowSuggestions(true);
    }
    setIsInputFocused(true);
  };

  function transformData(inputData: Room[]): OutputType[] {
    return inputData.map((entry, index) => {
      const numOfRoom = index + 1;
      const numOfAdults = entry.adults;
      const numOfChildren = entry.children.length;
      const childAges = entry.children.map(child => {
        const ageMatch = child.age.match(/\d+/);
        return ageMatch ? parseInt(ageMatch[0], 10) : 0;
      });

      return {
        numOfRoom,
        numOfAdults,
        numOfChildren,
        childAges,
      };
    });
  }

  function formatDateRange(dates: Date[]) {
    const minDate = dates[0];
    const maxDate = dates[1];

    // Format start date
    const startDate = formatDate(minDate);

    // Format end date
    const endDate = formatDate(maxDate);

    return { start: startDate, end: endDate };
  }

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = padZero(date.getMonth() + 1); // Months are zero-indexed
    const day = padZero(date.getDate());
    return `${year}-${month}-${day}`;
  }

  function padZero(num: number): string {
    return num.toString().padStart(2, '0');
  }

  const handleDateRangeChange = (value: [Date, Date] | null) => {
    if (value) {
      setDateRange(value);
    } else {
      setDateRange([new Date(), new Date()]);
    }
  };

  const searchHandler = () => {
    console.log('ENVVVV', process.env.REACT_APP_GOOGLE_API_KEY);
    if (!selectedPlace) {
      notify('Pleas select location ', 'warning');
      return;
    }

    if (!profile) {
      setIsModalOpen(true);
      return;
    }

    const params = {
      type: 'hotel',
      version: 'one',
      code: `${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }`,
      locationId: 'googleLocation',
      lat: coordinates.lat,
      long: coordinates.lng,
      nationality: `{"name": "${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.long_name
      }","alpha2Code":"${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }"}`,
      occupancies: JSON.stringify(transformData(roomsData)),
      paging: '{"pageNo":1,"pageSize":50,"orderBy":"rating desc, price desc"}',
      premise: inputValue,
      radius: 50,
      searchString: `${selectedPlace?.formatted_address}`,
      stayPeriod: `${JSON.stringify(formatDateRange(dateRange))}`,
      distance: 15,
      source: 'xplorious-dev',
      chlocality: selectedPlace?.address_components?.find((component: any) =>
        component.types.includes('locality'),
      )?.long_name,
    };
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = response?.accessToken;

        let paramString = '';
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            paramString =
              paramString + encodeURIComponent(`${key}=${params[key as keyof typeof params]}&`);
          }
        }

        const url = `${baseUrl}/sso?token=${token}&navigateTo=%2Fbooknow%2Fhotels%2Fsearch%3F${paramString}`;
        // console.log('url', url);
        window.location.href = url;
        // window.open(url, '_blank');
      },
    );
  };

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const optionsData: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCopperRed',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('booking'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowCopperRed',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCopperRed',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  if (appConfig?.isAdmin) {
    const newOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/coupons',
    };
    optionsData.splice(optionsData.length - 1, 0, newOption);
  }

  useEffect(() => {
    if (userAuth0?.sub && !profile?.account?.plan) {
      return navigate('/profile');
    }
  }, [userAuth0?.sub, profile?.account?.plan, location.pathname]);

  const formattedDateRange = `${formatFullDate(dateRange[0])} - ${formatFullDate(dateRange[1])}`;

  const userInfoSubmit = (data: { name: string; email: string; password: string }) => {
    console.log('ENVVVV', process.env.REACT_APP_GOOGLE_API_KEY);
    if (!selectedPlace) {
      notify('Pleas select location ', 'warning');
      return;
    }
    setIsModalOpen(false);

    const params = {
      type: 'hotel',
      version: 'one',
      code: `${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }`,
      locationId: 'googleLocation',
      lat: coordinates.lat,
      long: coordinates.lng,
      nationality: `{"name": "${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.long_name
      }","alpha2Code":"${
        selectedPlace?.address_components?.find((component: any) =>
          component.types.includes('country'),
        )?.short_name
      }"}`,
      occupancies: JSON.stringify(transformData(roomsData)),
      paging: '{"pageNo":1,"pageSize":50,"orderBy":"rating desc, price desc"}',
      premise: inputValue,
      radius: 50,
      searchString: `${selectedPlace?.formatted_address}`,
      stayPeriod: `${JSON.stringify(formatDateRange(dateRange))}`,
      distance: 15,
      source: 'xplorious-dev',
      chlocality: selectedPlace?.address_components?.find((component: any) =>
        component.types.includes('locality'),
      )?.long_name,
    };
    apiRequestHandler(
      {
        url: `auth/generate-public-user-token`,
        method: 'POST',
        payload: { email: data.email, name: data.name, password: data.password },
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = response?.accessToken;
        setToLocalStorage(`::@@xploriusJwtAuthspajs@@::`, response);
        setAccessToken(response?.accessToken);

        let paramString = '';
        for (const key in params) {
          if (Object.prototype.hasOwnProperty.call(params, key)) {
            paramString =
              paramString + encodeURIComponent(`${key}=${params[key as keyof typeof params]}&`);
          }
        }

        const url = `${baseUrl}/sso?token=${token}&navigateTo=%2Fbooknow%2Fhotels%2Fsearch%3F${paramString}`;
        // console.log('url', url);
        window.location.href = url;
        // window.open(url, '_blank');
      },
    );
  };

  useEffect(() => {
    if (error) {
      notify(error, 'error');
    }
  }, [error]);
  return (
    <>
      <UserInfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={data => {
          userInfoSubmit(data);
        }}
      />
      <div className="main_section relative">
        <div className="absolute inset-0 z-0 bg-black/70"></div>

        <img src={mainimg} className="mainimg mainimg_desc z-[-1]" />
        <img src={mainimg2} className="mainimg mainimg_mob z-[-1]" />

        <header className="header sm:mt-0 mt-8">
          <div className="container">
            <div className="lg:w-20"></div>
            <a href="/" className="logo">
              <img src={logo} className="logo_desc" />
              <img src={logoMob} className="logo_mob" />
            </a>

            <div
              className={`burger-menu ${menu ? 'active' : ''}`}
              // onClick={() => setMenu(prevState => !prevState)}
            >
              {/* <span></span>
              <span></span>
              <span></span> */}
            </div>

            <nav className={`main_menu ${menu ? 'main_menu_show' : ''}`}>
              <a href="/" className="main_menu_logo" onClick={() => setMenu(false)}>
                <img src={logo} />
              </a>

              <ul>
                {/* <li>
                <a href="#services" onClick={() => setMenu(false)}>
                  Signup
                </a>
              </li> */}
                {accessToken ? (
                  <li>
                    <a href="#" onClick={() => customLogoutHandler()}>
                      Logout
                    </a>
                  </li>
                ) : (
                  <li>
                    <a href="#" onClick={() => navigate('/login')}>
                      Login
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </header>

        <div className="main_section__wrap">
          <div className="main_section__content">
            <div className="container lg:max-w-[1500px]">
              <div className="flxcol">
                <div className="flex items-center justify-center lg:mt-32 sm:mt-0 mt-24">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="206"
                    height="45"
                    viewBox="0 0 206 45"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_5519_565)">
                      <path
                        d="M5.7428 44.2216L4.74515 36.6289H6.07533L6.60741 41.2687L7.28201 36.6383H8.33761L9.0407 41.2593L9.57278 36.6289H10.884L9.89583 44.2216H8.56564L7.81503 39.4128L7.10242 44.2216H5.7428Z"
                        fill="white"
                      />
                      <path
                        d="M25.023 44.3246C24.4585 44.3246 24.0121 44.2245 23.6832 44.0247C23.3553 43.8273 23.1044 43.5269 22.9705 43.1717C22.822 42.7402 22.7512 42.2866 22.7616 41.8311V39.0004C22.7505 38.5479 22.8213 38.0973 22.9705 37.6694C23.1032 37.3159 23.3548 37.0181 23.6832 36.8257C24.0121 36.6312 24.4585 36.5345 25.023 36.5352C25.5874 36.5356 26.0369 36.6357 26.3721 36.8351C26.7035 37.024 26.956 37.3231 27.0846 37.6787C27.2407 38.1019 27.3148 38.5502 27.3032 39.0004V41.8311C27.3138 42.2875 27.2396 42.7417 27.0846 43.1717C26.9546 43.5288 26.7027 43.8304 26.3721 44.0247C26.042 44.2245 25.5925 44.3246 25.023 44.3246ZM25.023 43.2091C25.1833 43.2223 25.3427 43.1753 25.4694 43.078C25.5678 42.977 25.6339 42.8499 25.6594 42.7124C25.6917 42.5487 25.7077 42.3823 25.707 42.2154V38.6255C25.7081 38.4618 25.6922 38.2983 25.6594 38.1379C25.6356 38.0025 25.5691 37.8779 25.4694 37.7818C25.4082 37.7304 25.3372 37.6915 25.2604 37.6675C25.1837 37.6432 25.1032 37.6343 25.023 37.6411C24.9459 37.6354 24.8683 37.6447 24.7947 37.669C24.7214 37.693 24.6536 37.7315 24.5953 37.7818C24.4957 37.8779 24.4293 38.0025 24.4053 38.1379C24.3724 38.2983 24.3564 38.4618 24.3579 38.6255V42.2154C24.3571 42.3818 24.3696 42.5481 24.3958 42.7124C24.4208 42.8501 24.4872 42.9774 24.5858 43.078C24.7095 43.1747 24.8657 43.2215 25.023 43.2091Z"
                        fill="white"
                      />
                      <path
                        d="M39.5135 44.2217V36.629H41.5088C41.9588 36.619 42.4077 36.6792 42.839 36.8069C43.1888 36.9021 43.4924 37.1185 43.6941 37.4163C43.8973 37.7094 43.9987 38.1156 43.9981 38.6349C44.0015 38.9153 43.9728 39.195 43.9126 39.4692C43.8665 39.6969 43.7691 39.9116 43.6276 40.0971C43.4783 40.2846 43.2817 40.4302 43.0575 40.5191L44.1216 44.2217H42.5159L41.6323 40.7814H41.0811V44.2217H39.5135ZM41.0811 39.8629H41.5372C41.7441 39.8731 41.95 39.8312 42.1358 39.7409C42.2801 39.6612 42.3888 39.5307 42.4398 39.3755C42.5082 39.1763 42.5403 38.967 42.5348 38.7567C42.5348 38.4071 42.4683 38.1409 42.3352 37.9601C42.2023 37.779 41.9685 37.6788 41.6131 37.6788H41.0811V39.8629Z"
                        fill="white"
                      />
                      <path
                        d="M56.1893 44.2216V36.6289H57.7569V43.1717H59.5622V44.2216H56.1893Z"
                        fill="white"
                      />
                      <path
                        d="M71.4084 44.2216V36.6289H73.2898C73.9415 36.6289 74.4482 36.7131 74.81 36.8819C75.1631 37.0302 75.443 37.3097 75.589 37.6598C75.741 38.0099 75.8171 38.4599 75.8171 39.0098V41.775C75.8171 42.3374 75.741 42.7997 75.589 43.1622C75.4445 43.5169 75.1696 43.8047 74.8196 43.9684C74.4642 44.1372 73.9665 44.2216 73.3267 44.2216H71.4084ZM72.9762 43.1435H73.3087C73.5995 43.1435 73.8054 43.0997 73.9264 43.0123C74.0556 42.9135 74.1408 42.7689 74.1638 42.6093C74.1982 42.3891 74.2142 42.1665 74.2114 41.9438V38.813C74.2169 38.6015 74.1946 38.3904 74.1449 38.1848C74.1311 38.1117 74.1019 38.0424 74.0594 37.981C74.0169 37.9198 73.9619 37.8677 73.8979 37.8286C73.7776 37.7536 73.578 37.7162 73.2993 37.7162H72.9762V43.1435Z"
                        fill="white"
                      />
                      <path
                        d="M103.705 44.3246C103.142 44.3246 102.696 44.2245 102.366 44.0247C102.038 43.8273 101.787 43.5269 101.653 43.1717C101.505 42.7402 101.434 42.2866 101.444 41.8311V39.0004C101.433 38.5479 101.504 38.0973 101.653 37.6694C101.786 37.3159 102.037 37.0181 102.366 36.8257C102.695 36.6312 103.142 36.5345 103.705 36.5352C104.269 36.5356 104.719 36.6357 105.055 36.8351C105.386 37.024 105.638 37.3231 105.767 37.6787C105.923 38.1019 105.997 38.5502 105.986 39.0004V41.8311C105.996 42.2875 105.922 42.7417 105.767 43.1717C105.637 43.5288 105.385 43.8304 105.055 44.0247C104.725 44.2245 104.275 44.3246 103.705 44.3246ZM103.705 43.2091C103.866 43.2223 104.025 43.1753 104.152 43.078C104.251 42.9774 104.317 42.8501 104.342 42.7124C104.374 42.5487 104.39 42.3823 104.389 42.2154V38.6255C104.391 38.4618 104.375 38.2983 104.342 38.1379C104.318 38.0025 104.252 37.8779 104.152 37.7818C104.091 37.7304 104.02 37.6915 103.943 37.6675C103.866 37.6432 103.786 37.6343 103.705 37.6411C103.628 37.6354 103.551 37.6447 103.477 37.669C103.404 37.693 103.336 37.7315 103.278 37.7818C103.178 37.8779 103.112 38.0025 103.088 38.1379C103.055 38.2983 103.039 38.4618 103.04 38.6255V42.2154C103.04 42.3818 103.052 42.5481 103.078 42.7124C103.103 42.8501 103.17 42.9774 103.268 43.078C103.392 43.1747 103.548 43.2215 103.705 43.2091Z"
                        fill="white"
                      />
                      <path
                        d="M118.197 44.2216V36.6289H121.446V37.6787H119.765V39.7314H121.19V40.8095H119.765V44.2216H118.197Z"
                        fill="white"
                      />
                      <path
                        d="M147.593 44.2216L146.595 36.6289H147.926L148.458 41.2687L149.132 36.6383H150.187L150.89 41.2593L151.422 36.6289H152.733L151.745 44.2216H150.415L149.664 39.4128L148.952 44.2216H147.593Z"
                        fill="white"
                      />
                      <path
                        d="M164.73 44.2216V36.6289H168.084V37.6974H166.297V39.7127H167.675V40.7906H166.297V43.1717H168.103V44.2216H164.73Z"
                        fill="white"
                      />
                      <path
                        d="M180.055 44.2217V36.629H182.003C182.316 36.6267 182.627 36.655 182.934 36.7132C183.202 36.7627 183.457 36.8648 183.685 37.0133C183.909 37.1596 184.085 37.3685 184.188 37.6132C184.321 37.9244 184.383 38.2602 184.369 38.5974C184.376 38.8658 184.331 39.1332 184.236 39.3849C184.158 39.5843 184.026 39.7592 183.856 39.891C183.677 40.0219 183.468 40.1089 183.248 40.1442C183.52 40.1775 183.779 40.2808 183.998 40.4441C184.205 40.605 184.363 40.819 184.455 41.0626C184.563 41.36 184.615 41.6748 184.607 41.9907C184.614 42.328 184.562 42.6641 184.455 42.9844C184.371 43.2465 184.225 43.4845 184.027 43.678C183.827 43.8652 183.586 44.0062 183.324 44.0903C183.009 44.1828 182.683 44.227 182.355 44.2217H180.055ZM181.623 43.1342H182.06C182.454 43.1342 182.723 43.0373 182.868 42.8437C183.013 42.65 183.089 42.3595 183.096 41.972C183.105 41.7301 183.07 41.4888 182.991 41.2595C182.932 41.0903 182.81 40.9495 182.649 40.8658C182.458 40.7697 182.246 40.7244 182.032 40.7346H181.623V43.1342ZM181.623 39.6942H182.013C182.225 39.7063 182.437 39.6676 182.63 39.5817C182.768 39.5031 182.87 39.3757 182.915 39.2255C182.977 39.0346 183.006 38.835 183.001 38.635C183.01 38.443 182.968 38.2522 182.877 38.0819C182.801 37.9376 182.672 37.8272 182.516 37.7726C182.321 37.704 182.115 37.6721 181.908 37.6789H181.623V39.6942Z"
                        fill="white"
                      />
                      <path
                        d="M198.529 44.3526C198.016 44.3526 197.607 44.2588 197.303 44.0713C197.006 43.8781 196.779 43.5954 196.657 43.2653C196.518 42.8804 196.45 42.474 196.457 42.0654V41.7935H197.921V42.0467C197.923 42.251 197.941 42.4548 197.978 42.6559C198.004 42.7907 198.07 42.9148 198.168 43.0122C198.274 43.0997 198.41 43.1433 198.548 43.134C198.616 43.1395 198.685 43.1316 198.75 43.1108C198.816 43.0897 198.876 43.0564 198.928 43.0122C199.019 42.905 199.078 42.7754 199.099 42.6372C199.133 42.4391 199.149 42.2382 199.146 42.0374C199.146 41.656 199.089 41.3623 198.975 41.1561C198.861 40.9433 198.633 40.8347 198.291 40.8281H198.035V39.7596H198.263C198.605 39.7596 198.836 39.6688 198.956 39.4877C199.083 39.3068 199.146 39.019 199.146 38.6347C199.158 38.3932 199.112 38.1526 199.013 37.9315C198.924 37.7628 198.76 37.6786 198.518 37.6786C198.277 37.6786 198.119 37.7723 198.043 37.9598C197.959 38.1913 197.918 38.4357 197.921 38.6815V39.019H196.457V38.6064C196.445 38.2172 196.53 37.8308 196.705 37.4818C196.86 37.1789 197.105 36.9302 197.408 36.7693C197.751 36.5977 198.133 36.514 198.518 36.5255C198.906 36.5146 199.291 36.595 199.64 36.7599C199.943 36.9087 200.189 37.1483 200.344 37.4441C200.519 37.79 200.604 38.1734 200.591 38.5597C200.605 38.9478 200.506 39.3317 200.306 39.6658C200.156 39.9307 199.912 40.1311 199.621 40.228C199.811 40.2912 199.984 40.397 200.125 40.5375C200.283 40.7023 200.4 40.9012 200.466 41.1187C200.556 41.4069 200.598 41.7077 200.591 42.0091C200.598 42.4238 200.53 42.8364 200.391 43.2277C200.268 43.565 200.038 43.8543 199.735 44.0526C199.431 44.2527 199.029 44.3526 198.529 44.3526Z"
                        fill="white"
                      />
                      <path
                        d="M4.74896 31.0982L0.758392 0.727539H6.07914L8.20744 19.2874L10.903 0.765033H15.1216L17.934 19.2499L20.0651 0.727539H25.3089L21.3545 31.0982H16.0337L13.0341 11.8635L10.1837 31.0982H4.74896Z"
                        fill="white"
                      />
                      <path
                        d="M37.2112 31.5107C34.9568 31.5107 33.1707 31.1107 31.8526 30.3108C30.5343 29.5109 29.5841 28.3735 29.0021 26.8988C28.4452 25.399 28.1666 23.6117 28.1659 21.537V10.2136C28.1659 8.13892 28.4446 6.36419 29.0021 4.88939C29.5854 3.41524 30.5355 2.29039 31.8526 1.51487C33.1694 0.739361 34.9555 0.351915 37.2112 0.35254C39.4916 0.35254 41.2905 0.752482 42.6079 1.55237C43.9515 2.32726 44.9017 3.45209 45.4583 4.92688C46.0418 6.37731 46.3333 8.13956 46.3326 10.2136V21.537C46.3326 23.6111 46.0412 25.3983 45.4583 26.8988C44.9393 28.3285 43.9313 29.535 42.6079 30.3108C41.2911 31.1107 39.4922 31.5107 37.2112 31.5107ZM37.2112 27.0488C38.0226 27.0488 38.6182 26.8737 38.9976 26.5238C39.3923 26.1214 39.6567 25.6126 39.7577 25.0616C39.8876 24.4069 39.9511 23.7412 39.9477 23.0744V8.71386C39.9524 8.05923 39.8888 7.40585 39.7577 6.76413C39.631 6.16422 39.3776 5.68927 38.9976 5.33933C38.6175 4.96438 38.0219 4.77692 37.2112 4.77692C36.4511 4.77692 35.881 4.96438 35.501 5.33933C35.1209 5.68865 34.8675 6.1636 34.7408 6.76413C34.6101 7.40593 34.5466 8.05926 34.5508 8.71386V23.0744C34.5485 23.7397 34.5993 24.4042 34.7028 25.0616C34.8042 25.6124 35.0686 26.1212 35.4629 26.5238C35.843 26.8737 36.4258 27.0488 37.2112 27.0488Z"
                        fill="white"
                      />
                      <path
                        d="M53.2942 31.0982L49.3036 0.727539H54.6244L56.7525 19.2874L59.4548 0.765033H63.6735L66.4859 19.2499L68.6102 0.727539H73.8551L69.9062 31.0982H64.5856L61.5832 11.8635L58.7327 31.0982H53.2942Z"
                        fill="white"
                      />
                      <path
                        d="M94.6505 31.5107C92.7503 31.5107 91.1669 31.1732 89.9 30.4983C88.6332 29.7977 87.6703 28.7604 87.0116 27.3862C86.3781 25.9871 86.0108 24.2122 85.9094 22.062L91.3061 21.1621C91.3573 22.4118 91.4842 23.4617 91.6862 24.3117C91.9143 25.1364 92.2437 25.7614 92.6744 26.1863C93.1346 26.588 93.7323 26.8025 94.3466 26.7863C95.1579 26.7863 95.7027 26.5364 95.9808 26.0364C96.2909 25.514 96.4488 24.9171 96.4369 24.3117C96.4369 23.0368 96.1202 21.9744 95.4868 21.1246C94.8786 20.2497 94.0679 19.3748 93.0544 18.5L89.859 15.7628C88.7486 14.8475 87.7984 13.7581 87.0466 12.5383C86.3379 11.3385 85.9832 9.86368 85.9825 8.11395C85.9825 5.6143 86.7174 3.70206 88.1868 2.37725C89.6817 1.02744 91.7223 0.352539 94.3085 0.352539C95.8541 0.352539 97.1209 0.602503 98.1091 1.10243C99.0973 1.60236 99.8574 2.27727 100.389 3.12715C100.949 3.95627 101.336 4.88654 101.53 5.86425C101.756 6.82511 101.896 7.80376 101.948 8.78885L96.5889 9.57623C96.5502 8.75928 96.4614 7.94541 96.3228 7.13908C96.2216 6.43918 95.9935 5.88925 95.6387 5.48932C95.3088 5.08936 94.8021 4.88938 94.1185 4.88938C93.3837 4.88938 92.8391 5.16435 92.4843 5.71427C92.13 6.23504 91.9436 6.84934 91.9493 7.47652C91.9493 8.55137 92.1902 9.43875 92.6714 10.1386C93.2789 10.9191 93.9667 11.6353 94.7239 12.2758L97.8402 14.9755C99.1269 16.0688 100.241 17.3452 101.147 18.7624C102.085 20.1622 102.554 21.8996 102.553 23.9742C102.553 25.399 102.223 26.6862 101.565 27.8362C100.916 28.9761 99.9548 29.912 98.7904 30.5357C97.6268 31.1857 96.2469 31.5107 94.6505 31.5107Z"
                        fill="white"
                      />
                      <path
                        d="M114.523 31.5108C112.192 31.5108 110.38 31.1233 109.088 30.3483C107.796 29.549 106.884 28.4118 106.352 26.9362C105.845 25.4366 105.591 23.6243 105.591 21.4996V0.727539H111.786V22.3619C111.786 23.1019 111.837 23.8409 111.938 24.5741C112.065 25.2991 112.318 25.8864 112.698 26.3364C113.104 26.7864 113.712 27.0113 114.523 27.0113C115.359 27.0113 115.967 26.7864 116.347 26.3364C116.752 25.8864 117.006 25.2991 117.107 24.5741C117.209 23.8412 117.26 23.1019 117.259 22.3619V0.727539H123.454V21.4996C123.454 23.6243 123.188 25.4366 122.656 26.9362C122.149 28.4106 121.25 29.5478 119.957 30.3483C118.665 31.1239 116.854 31.5114 114.523 31.5108Z"
                        fill="white"
                      />
                      <path
                        d="M127.715 31.0982L128.244 0.727539H134.895L138.696 19.8873L142.766 0.727539H149.188L149.72 31.0982H145.16L144.666 10.5137L140.751 31.0982H136.837L132.732 10.3637L132.314 31.0982H127.715Z"
                        fill="white"
                      />
                      <path
                        d="M154.141 31.0982L154.673 0.727539H161.324L165.125 19.8873L169.195 0.727539H175.618L176.15 31.0982H171.59L171.096 10.5137L167.177 31.0982H163.263L159.158 10.3637L158.744 31.0982H154.141Z"
                        fill="white"
                      />
                      <path d="M180.423 31.0982V0.727539H186.656V31.0982H180.423Z" fill="white" />
                      <path
                        d="M194.258 31.0982V5.33939H189.507V0.727539H205.241V5.33939H200.567V31.0982H194.258Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5519_565">
                        <rect
                          width="204.483"
                          height="44"
                          fill="white"
                          transform="translate(0.758423 0.352539)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <p className="text-white lg:text-[44px] sm:text-2xl text-xl font-inter text-center font-semibold uppercase leading-normal lg:mt-11 sm:mt-4 mt-8">
                  Your Hong Kong <br className="sm:hidden block" /> stay, the smart way
                </p>
                <p className="text-white lg:text-[28px] text-xs font-inter text-center font-normal uppercase leading-normal lg:mt-8 sm:mt-4 mt-8">
                  Secure exclusive WOW Summit rates with <br className="lg:hidden block" /> savings
                  up to 60%. Pay with card or crypto.
                </p>

                <div
                  className="flex lg:flex-row lg:items-center flex-col justify-between lg:h-[95px] bg-white p-4 gap-[15px] lg:mt-10 sm:mt-[34px] mt-12 rounded-[20px]  lg:w-full sm:w-[320px] sm:mx-auto"
                  style={{ boxShadow: '0px 0px 48.7px 0px rgba(255, 255, 255, 0.50)' }}
                >
                  <div className="w-full lg:border-r lg:border-b-0 border-b-[0.5px] lg:pb-0 pb-3 border-solid border-[#959595]">
                    <div className="lg:px-4">
                      <div
                        className="relative w-full flex items-center  space-x-2"
                        ref={wrapperRef}
                      >
                        <span className="w-7 h-7">
                          <IconSvg icon="locationMarker" className="w-7 h-7" />
                        </span>

                        <div className="flex flex-col w-full">
                          <p className="font-inter text-[#4A4A4A] text-base font-medium">Where</p>

                          <input
                            type="text"
                            placeholder="Where are you going"
                            value={inputValue}
                            onChange={handleInputChange}
                            className=""
                            ref={inputRef}
                            onFocus={handleInputFocus}
                            // onBlur={handleInputBlur}
                          />
                        </div>
                        {showSuggestions && isInputFocused && placePredictions.length > 0 && (
                          <ul className="autocomplete-suggestions mt-2">
                            {placePredictions.map(prediction => (
                              <li
                                key={prediction.place_id}
                                className="autocomplete-suggestion"
                                onClick={() => handlePlaceSelect(prediction.place_id)}
                              >
                                <div className="flex items-center gap-3 px-3">
                                  <IconSvg icon="dotLocationMarkerGreen" />
                                  <div>
                                    <p className="suggestion-main">
                                      {prediction.structured_formatting.main_text}
                                    </p>
                                    <p className="suggestion-secondary">
                                      {prediction.structured_formatting.secondary_text}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full lg:border-r lg:border-b-0 border-b-[0.5px] lg:pb-0 pb-3 border-solid border-[#959595]">
                    <div
                      className={`${Styles['date-range-picker-booking']} flex items-center bg-white rounded-[5px]`}
                    >
                      <IconSvg icon="calendar" className="w-8 h-8" />
                      <div className="flex flex-col w-full">
                        <p className="font-inter text-[#4A4A4A] text-base font-medium pl-3">
                          Dates
                        </p>

                        <DateRangePicker
                          value={dateRange}
                          onChange={handleDateRangeChange}
                          format="MM/dd/yyyy"
                          character="-"
                          className="w-full  bg-white rounded-[5px]"
                          color="#EFE8DF"
                          showHeader={false}
                          caretAs={null}
                          aria-label="text-xl"
                          menuStyle={{ backgroundColor: '#FFF' }}
                          menuClassName="booking-menu"
                          renderValue={() => formattedDateRange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full ">
                    <GuestsMenuCard
                      onChange={(value: string, name: string) => console.log('menu')}
                      className="w-full"
                      placeholder={`${totalAdults} adult | ${totalChildren} children | ${totalRooms} room`}
                      onApplyHandler={submitGuestHandler}
                    />
                  </div>
                  {/* For Desktop */}
                  <div
                    className={`lg:w-[216px] w-full lg:flex hidden items-center rounded-[5px] ${'cursor-pointer'} `}
                    onClick={() => searchHandler()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="54"
                      height="55"
                      viewBox="0 0 54 55"
                      fill="none"
                    >
                      <rect y="0.852539" width="54" height="54" rx="14.2105" fill="#9B2626" />
                      <path
                        d="M25.252 36.8054C23.1357 36.8054 21.0668 36.1778 19.3072 35.002C17.5475 33.8262 16.1759 32.155 15.366 30.1997C14.5561 28.2445 14.3442 26.093 14.7571 24.0173C15.17 21.9416 16.1891 20.0349 17.6856 18.5384C19.1821 17.0419 21.0888 16.0228 23.1645 15.6099C25.2402 15.197 27.3917 15.4089 29.347 16.2188C31.3022 17.0287 32.9734 18.4002 34.1492 20.1599C35.325 21.9196 35.9526 23.9885 35.9526 26.1048C35.9526 27.51 35.6758 28.9015 35.138 30.1997C34.6003 31.498 33.8121 32.6776 32.8185 33.6712C31.8248 34.6649 30.6452 35.4531 29.347 35.9908C28.0487 36.5286 26.6573 36.8054 25.252 36.8054ZM25.252 17.5501C23.5657 17.5501 21.9172 18.0502 20.5151 18.987C19.1129 19.9239 18.0201 21.2555 17.3748 22.8135C16.7294 24.3715 16.5606 26.0859 16.8896 27.7398C17.2185 29.3938 18.0306 30.913 19.223 32.1054C20.4155 33.2978 21.9347 34.1099 23.5886 34.4389C25.2426 34.7679 26.9569 34.599 28.5149 33.9537C30.0729 33.3084 31.4045 32.2155 32.3414 30.8134C33.2783 29.4112 33.7784 27.7628 33.7784 26.0764C33.7784 23.8151 32.8801 21.6464 31.2811 20.0474C29.6821 18.4484 27.5134 17.5501 25.252 17.5501Z"
                        fill="white"
                      />
                      <path
                        d="M38.3685 40.2864C38.2284 40.2871 38.0897 40.2598 37.9604 40.2061C37.831 40.1524 37.7137 40.0734 37.6153 39.9738L31.7464 34.1049C31.5581 33.9028 31.4556 33.6356 31.4605 33.3595C31.4654 33.0834 31.5772 32.8199 31.7725 32.6247C31.9678 32.4294 32.2312 32.3175 32.5073 32.3127C32.7834 32.3078 33.0507 32.4103 33.2527 32.5985L39.1216 38.4675C39.3212 38.6673 39.4333 38.9382 39.4333 39.2207C39.4333 39.5031 39.3212 39.774 39.1216 39.9738C39.0232 40.0734 38.9059 40.1524 38.7766 40.2061C38.6473 40.2598 38.5085 40.2871 38.3685 40.2864Z"
                        fill="white"
                      />
                    </svg>
                    {/* <div className="lg:w-[216px] text-center w-full lg:py-0 sm:py-[14px] py-2 flex justify-center items-center bg-[#4C4C4C] rounded-[5px]">
                    <p className="uppercase font-medium font-inter  text-xl text-[#EFE8DF]">
                      search
                    </p>
                  </div> */}
                  </div>

                  {/* FOr mobile */}
                  <div
                    className={`w-full bg-[#9B2626] lg:hidden flex items-center rounded-[8px] mt-4 h-8 py-2 justify-center ${'cursor-pointer'} `}
                    onClick={() => searchHandler()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M6.9687 13.3052C5.71455 13.3052 4.48858 12.9333 3.4458 12.2365C2.40301 11.5397 1.59027 10.5494 1.11033 9.39072C0.630387 8.23204 0.504813 6.95707 0.749484 5.72702C0.994155 4.49698 1.59808 3.36711 2.48489 2.4803C3.37171 1.59349 4.50157 0.989562 5.73162 0.744891C6.96166 0.50022 8.23664 0.625794 9.39531 1.10573C10.554 1.58567 11.5443 2.39842 12.2411 3.4412C12.9379 4.48398 13.3097 5.70996 13.3097 6.9641C13.3097 7.79682 13.1457 8.62139 12.8271 9.39072C12.5084 10.1601 12.0413 10.8591 11.4525 11.4479C10.8637 12.0367 10.1646 12.5038 9.39531 12.8225C8.62598 13.1411 7.80141 13.3052 6.9687 13.3052ZM6.9687 1.89463C5.96938 1.89463 4.9925 2.19096 4.1616 2.74615C3.3307 3.30134 2.68309 4.09045 2.30067 5.0137C1.91825 5.93695 1.81819 6.95286 2.01315 7.93298C2.20811 8.91309 2.68932 9.81339 3.39595 10.52C4.10257 11.2266 5.00286 11.7079 5.98298 11.9028C6.96309 12.0978 7.97901 11.9977 8.90225 11.6153C9.8255 11.2329 10.6146 10.5853 11.1698 9.75435C11.725 8.92345 12.0213 7.94658 12.0213 6.94726C12.0213 5.60722 11.489 4.32206 10.5414 3.37451C9.59389 2.42696 8.30874 1.89463 6.9687 1.89463Z"
                        fill="white"
                      />
                      <path
                        d="M14.7413 15.3679C14.6584 15.3683 14.5761 15.3521 14.4995 15.3203C14.4229 15.2885 14.3533 15.2417 14.295 15.1826L10.8171 11.7047C10.7056 11.585 10.6448 11.4266 10.6477 11.263C10.6506 11.0994 10.7169 10.9433 10.8326 10.8276C10.9483 10.7118 11.1044 10.6456 11.2681 10.6427C11.4317 10.6398 11.59 10.7005 11.7098 10.8121L15.1877 14.29C15.3059 14.4084 15.3724 14.5689 15.3724 14.7363C15.3724 14.9037 15.3059 15.0642 15.1877 15.1826C15.1294 15.2417 15.0598 15.2885 14.9832 15.3203C14.9066 15.3521 14.8243 15.3683 14.7413 15.3679Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
