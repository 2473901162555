
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import serv1 from '../../../assets/assets-homepage/img/serv1.webp';

import serv2 from '../../../assets/assets-homepage/img/serv2.webp';

import serv3 from '../../../assets/assets-homepage/img/serv3.webp';

import serv4 from '../../../assets/assets-homepage/img/serv4.webp';

import serv5 from '../../../assets/assets-homepage/img/serv5.webp';

import serv6 from '../../../assets/assets-homepage/img/serv6.webp';

import serv7 from '../../../assets/assets-homepage/img/serv7.webp';
const ServicesSlider = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const services = [
    {
      id: 1,
      image: serv1,
      title: 'Hotel Bookings &\nBespoke Itineraries',
    },
    {
      id: 2,
      image: serv2,
      title: 'Corporate \nTravel',
    },
    {
      id: 3,
      image: serv3,
      title: 'HNW\nFamilies',
    },
    {
      id: 4,
      image: serv4,
      title: 'Villa \nRentals',
    },
    {
      id: 5,
      image: serv5,
      title: 'Transfers',
    },
    {
      id: 6,
      image: serv6,
      title: 'Luxury Car\nRentals',
    },
    {
      id: 7,
      image: serv7,
      title: 'Yacht \nRentals',
    },
  ];

  return (
    <>
      <div className="services" id="services">
        <div className="container">
          <h2 className="title_2">Services</h2>

          <div className="services__wrap">
            {!isMobile && (
              <div className="swiper services_slider">
                <div className="swiper-wrapper">
                  {services.map((service) => (
                    <div className="swiper-slide" key={service.id}>
                      <div className="services_item">
                        <div className="services_item__img">
                          <img
                            src={service.image}
                            alt={`Service ${service.id}`}
                          />
                        </div>

                        <h5 className="services_item__title">
                          {service.title.split('\n').map((line, i) => (
                            <React.Fragment key={i}>
                              {line}
                              {i < service.title.split('\n').length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                        </h5>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {isMobile && (
              <Swiper
                slidesPerView="auto"
                spaceBetween={20}
                loop={true}
                centeredSlides={true}
                speed={100}
                modules={[Pagination, Autoplay, FreeMode]}
                pagination={{
                  el: '.services_pagination',
                  clickable: true,
                }}
                breakpoints={{
                  0: {
                    centeredSlides: false,
                    spaceBetween: 27,
                    freeMode: true,
                    speed: 1000,
                  },
                  640: {
                    centeredSlides: true,
                    spaceBetween: 50,
                    autoplay: {
                      delay: 1000,
                      disableOnInteraction: false,
                    },
                  },
                }}
                className="services_slider"
              >
                {services.map((service) => (
                  <SwiperSlide key={service.id}>
                    <div className="services_item">
                      <div className="services_item__img">
                        <img
                          src={service.image}
                          alt={`Service ${service.id}`}
                        />
                      </div>

                      <h5 className="services_item__title">
                        {service.title.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            {i < service.title.split('\n').length - 1 && <br />}
                          </React.Fragment>
                        ))}
                      </h5>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>

          <p className="services__txt">
            <span>Got something else on your mind?</span> Let&apos;s make it happen.
          </p>

          <div className="pagination services_pagination"></div>
        </div>
      </div>
    </>
  );
};

export default ServicesSlider;
