import vip1 from '../../../assets/assets-wowsummit/img/vip1.png';
import vipHotel1 from '../../../assets/assets-wowsummit/img/vip-hotel1.png';
import { AboutHotelModal } from 'src/components/common/modal/AboutHotelModal';
import { useState } from 'react';

export default function VipPackage() {
  const [isHotelModalOpen, setHotelModalOpen] = useState(false);
  const navigateToWhatsapp = () => {
    window.open('https://wa.me/message/6GFBB37QHD6UN1', '_blank');
  };
  return (
    <>
      <AboutHotelModal isOpen={isHotelModalOpen} onClose={() => setHotelModalOpen(false)} />

      <div className="why_us mt-32" id="why_us">
        <div className="container">
          <h2 className="text-center font-inter lg:text-[32px] text-lg font-semibold text-[#0f0f0f]">
            EXCLUSIVE VIP PACKAGE
          </h2>
          <p className="font-inter font-normal lg:text-lg text-xs text-[#4c4c4c] text-center lg:mt-4 mt-3">
            For those who settle for nothing but the best.
          </p>

          <div className="vip-package">
            <div className="w-full h-full relative">
              {/* <div className="absolute top-[40%] left-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <rect
                    width="49"
                    height="48"
                    rx="24"
                    transform="matrix(-1 0 0 1 49 0.557617)"
                    fill="#EFE8DF"
                  />
                  <path
                    d="M29.0003 16.2029L27.3642 14.5684L18.4529 23.4766C18.3093 23.6194 18.1953 23.7891 18.1175 23.9761C18.0397 24.163 17.9996 24.3635 17.9996 24.566C17.9996 24.7686 18.0397 24.9691 18.1175 25.156C18.1953 25.343 18.3093 25.5127 18.4529 25.6555L27.3642 34.5684L28.9988 32.9338L20.6349 24.5684L29.0003 16.2029Z"
                    fill="#4C4C4C"
                  />
                </svg>
              </div>

              <div className="absolute top-[40%] right-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <rect y="0.557617" width="49" height="48" rx="24" fill="#EFE8DF" />
                  <path
                    d="M19.9997 16.2029L21.6358 14.5684L30.5471 23.4766C30.6907 23.6194 30.8047 23.7891 30.8825 23.9761C30.9603 24.163 31.0004 24.3635 31.0004 24.566C31.0004 24.7686 30.9603 24.9691 30.8825 25.156C30.8047 25.343 30.6907 25.5127 30.5471 25.6555L21.6358 34.5684L20.0012 32.9338L28.3651 24.5684L19.9997 16.2029Z"
                    fill="#4C4C4C"
                  />
                </svg>
              </div> */}

              <img src={vip1} className="slider-img" />
            </div>
            <div className="px-5 pb-10 pt-8 w-full relative">
              <div className="absolute profile lg:-top-14 -top-8 lg:left-6 left-4 flex items-center justify-center">
                <img src={vipHotel1} className="" />
              </div>
              <div className="flex lg:flex-row flex-col  lg:items-center lg:justify-between lg:space-y-0 space-y-4 mt-8">
                <p className="text-black font-inter font-semibold text-xl uppercase leading-6">
                  The Peninsula Hong Kong
                </p>
                <button
                  onClick={() => setHotelModalOpen(true)}
                  type="button"
                  className="btn cursor-pointer py-3 px-5 font-inter text-[#4A4A4A] leading-normal uppercase font-bold text-sm"
                >
                  About the hotel
                </button>
              </div>

              <p className="font-inter lg:text-base text-sm font-semibold text-black leading-4 my-5">
                Inclusions:
              </p>
              <div className="flex flex-col space-y-5 leading-6">
                <div className="flex items-center space-x-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle cx="6.1842" cy="6.20471" r="5.6842" fill="#9B2626" />
                    </svg>
                  </div>
                  <p className="font-inter lg:text-base text-xs font-normal text-[#4A4A4A]">
                  Special rates (inquire from our agents)
                  </p>
                </div>

                <div className="flex items-center space-x-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle cx="6.1842" cy="6.20471" r="5.6842" fill="#9B2626" />
                    </svg>
                  </div>
                  <p className="font-inter lg:text-base text-xs font-normal text-[#4A4A4A]">
                  Complimentary breakfast
                  </p>
                </div>

                <div className="flex items-center space-x-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle cx="6.1842" cy="6.20471" r="5.6842" fill="#9B2626" />
                    </svg>
                  </div>
                  <p className="font-inter lg:text-base text-xs font-normal text-[#4A4A4A]">
                  Complimentary transfer in a luxury limousine
                  </p>
                </div>

                <div className="flex items-center space-x-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <circle cx="6.1842" cy="6.20471" r="5.6842" fill="#9B2626" />
                    </svg>
                  </div>
                  <p className="font-inter lg:text-base text-xs font-normal text-[#4A4A4A]">
                  Concierge support
                  </p>
                </div>


              </div>

              <div className="flex items-center justify-center mt-10">
                <button onClick={() => navigateToWhatsapp()} className="lg:max-w-[569px] w-full lg:mx-auto lg:px-10 px-5 lg:py-3 py-2 leading-normal bg-[#9B2626] lg:rounded-[18px] rounded-xl text-white cursor-pointer font-inter lg:text-xl text-sm font-medium">
                  SPEAK TO AN AGENT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
