import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';

import choo1 from '../../../assets/assets-wowsummit/img/choo1.jpg';
import choo2 from '../../../assets/assets-wowsummit/img/choo2.jpg';
import choo3 from '../../../assets/assets-wowsummit/img/choo3.jpg';
import choo4 from '../../../assets/assets-wowsummit/img/choo4.jpg';
import choo5 from '../../../assets/assets-wowsummit/img/choo5.jpg';
import choo6 from '../../../assets/assets-wowsummit/img/choo6.jpg';
import choo7 from '../../../assets/assets-wowsummit/img/choo7.jpg';

const clients = [
  { img: choo1, label: 'Hong Kong SkyCity Marriott Hotel' },
  { img: choo2, label: 'Sheraton Hong Kong Tung Chung Hotel' },
  { img: choo3, label: 'The Ritz-Carlton, Hong Kong' },
  { img: choo4, label: 'The Upper House' },
  { img: choo5, label: 'K11 ARTUS' },
  { img: choo6, label: 'The Fleming' },
  { img: choo7, label: 'AKI Hong Kong - MGallery' },
];

const HotelsRecommend = () => {
  const swiperRef = useRef<any | null>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (!swiperRef.current) return;
      const swiper = swiperRef.current.swiper;
      const clickedSlide = e.target.closest('.swiper-slide');

      if (!clickedSlide) return;

      if (
        clickedSlide.classList.contains('swiper-slide-next') ||
        clickedSlide.classList.contains('swiper-slide-active')
      ) {
        swiper.slideNext();
      } else if (clickedSlide.classList.contains('swiper-slide-prev')) {
        swiper.slidePrev();
      }
    };

    const sliderElement = swiperRef.current;
    if (sliderElement) {
      sliderElement.addEventListener('click', handleClick);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div className="clients_choose">
      <div className="container">
        <h2 className="text-center font-inter lg:text-[32px] text-lg uppercase leading-normal font-semibold text-[#0f0f0f] my-11">
          Hotels we recommend in Honk kong
        </h2>
        <Swiper
          ref={swiperRef}
          modules={[Pagination]}
          loop={true}
          slidesPerView={3}
          centeredSlides={true}
          pagination={{ clickable: true, el: '.clients_choose_pagination' }}
          speed={0}
          breakpoints={{
            0: { slidesPerView: 1, loop: true },
            640: { slidesPerView: 1, centeredSlides: false, loop: true },
            992: { slidesPerView: 3, centeredSlides: true, loop: true },
          }}
          className="clients_choose_slider"
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index}>
              <div className="clients_choose_item">
                <div className="clients_choose_item__img">
                  <img src={client.img} alt={client.label} />
                </div>
                <div className="clients_choose_item__label">
                  <span>{client.label}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="mt-10 w-full">
          <div className="pagination clients_choose_pagination flex items-center justify-center space-x-2"></div>

          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default HotelsRecommend;
