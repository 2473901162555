import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';

// Import all creme images
import cm1 from '../../../assets/assets-homepage/img/cm1.webp';
import cm2 from '../../../assets/assets-homepage/img/cm2.webp';
import cm3 from '../../../assets/assets-homepage/img/cm3.webp';
import cm4 from '../../../assets/assets-homepage/img/cm4.webp';
import cm5 from '../../../assets/assets-homepage/img/cm5.webp';
import cm6 from '../../../assets/assets-homepage/img/cm6.webp';
import cm7 from '../../../assets/assets-homepage/img/cm7.webp';
import cm8 from '../../../assets/assets-homepage/img/cm8.webp';
import cm9 from '../../../assets/assets-homepage/img/cm9.webp';
import cm10 from '../../../assets/assets-homepage/img/cm10.webp';
import cm11 from '../../../assets/assets-homepage/img/cm11.webp';
import cm12 from '../../../assets/assets-homepage/img/cm12.webp';
import cm13 from '../../../assets/assets-homepage/img/cm13.webp';
import cm14 from '../../../assets/assets-homepage/img/cm14.webp';
import cm15 from '../../../assets/assets-homepage/img/cm15.webp';
import cm16 from '../../../assets/assets-homepage/img/cm16.webp';

const CremeSection = () => {
  // Array of imported images for the slider
  const cremeImages = [
    cm1, cm2, cm3, cm4, cm5, cm6, cm7, cm8,
    cm9, cm10, cm11, cm12, cm13, cm14, cm15, cm16
  ];

  return (
    <div className="creme">
      <div className="container">
        <h2 className="title_2_1">ONLY CRÈME DE LA CRÈME OF HOSPITALITY</h2>

        <Swiper
          spaceBetween={72}
          slidesPerGroup={2}
          loop={true}
          slidesPerView={6}
          freeMode={true}
          speed={4000}
          modules={[Pagination, Autoplay, FreeMode]}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            stopOnLastSlide: false,
            reverseDirection: false
          }}
          pagination={{
            el: '.creme_pagination',
            clickable: true
          }}
          breakpoints={{
            0: {
              spaceBetween: 0,
              slidesPerView: 4,
              autoplay: false,
              freeMode: false,
              speed: 500
            },
            640: {
              spaceBetween: 0,
              slidesPerView: 4,
              speed: 4000
            },
            992: {
              spaceBetween: 0,
              slidesPerView: 4,
              speed: 4000
            },
            1380: {
              spaceBetween: 72,
              slidesPerView: 6,
              speed: 4000
            }
          }}
          className="creme_slider"
        >
          {cremeImages.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="creme_item">
                <img src={image} alt={`Luxury hospitality partner ${index + 1}`} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="pagination creme_pagination"></div>
      </div>
    </div>
  );
};

export default CremeSection;