
import { useState } from 'react';
import owner from '../../../assets/assets-homepage/img/owner.webp';
export default function Founder() {
  const [more, setMore] = useState(false)
  return (
    <div className="founder">
      <div className="container">
        <h2 className="title_2">founder</h2>
      </div>

      <div className="founder_sec1">
        <div className="container">
          <div className="founder__photo">
            <div>
              <img src={owner} />
            </div>
          </div>
        </div>
      </div>

      <div className="founder__text">
        <div className="container">
          <div className="founder__text_sec">
            <p>
              With a strong background in luxury hospitality PR and
              communications, Nina has worked with{' '}
              <strong>
                Four Seasons Hotels & Resorts, JOALI Maldives, Palazzo Versace
                Dubai, Regnum Carya, Chenot Group, and Les Grandes Alpes
              </strong>
              . Her expertise in high-end travel and client relations led her to
              establish <strong>XPLORIUS</strong>, a bespoke luxury
              <span className={more?"moretext moretext_show":"moretext"}>
                travel agency.
                <br />
                <br />
                Born in Kazakhstan, she is multicultural and has lived in London
                for seven years, earning her BA and MA from <strong>UCL</strong>
                , before spending four years in Moscow and eventually settling
                in Dubai.
                <br />
                <br />
                Follow Nina on{' '}
                <strong>
                  <a href="https://www.instagram.com/ninakovalenko/">
                    Instagram
                  </a>
                </strong>
                .
              </span>
              {!more&&<span className="readmore" onClick={()=>setMore(true)}>+</span>}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
