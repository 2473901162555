import ic1 from '../../../assets/assets-homepage/img/icons/ic1.svg';
import ic2 from '../../../assets/assets-homepage/img/icons/ic2.svg';
import ic3 from '../../../assets/assets-homepage/img/icons/ic3.svg';
import ic4 from '../../../assets/assets-homepage/img/icons/ic4.svg';
export default function WhyUs() {
  return (
    <div className="why_us" id="why_us">
    <div className="container">

      <h2 className="title_2">Why choose us</h2>

      <div className="why_us__list">

        <div className="why_us_item">
          <div className="why_us_item__ic"><img src={ic1} /></div>

          <h5 className="why_us_item__title">Best hotels at the best value</h5>

          <p className="why_us_item__txt">Unlock off-market pricing</p>
        </div>

        <div className="why_us_item">
          <div className="why_us_item__ic"><img src={ic2} /></div>

          <h5 className="why_us_item__title">Flawless white-glove service</h5>

          <p className="why_us_item__txt">Every detail, perfected</p>
        </div>

        <div className="why_us_item">
          <div className="why_us_item__ic"><img src={ic3} /></div>

          <h5 className="why_us_item__title">VIP perks</h5>

          <p className="why_us_item__txt">Upgrades, F&B credit, early check-in & late check-out</p>
        </div>

        <div className="why_us_item">
          <div className="why_us_item__ic"><img src={ic4} /></div>

          <h5 className="why_us_item__title">Absolute discretion</h5>

          <p className="why_us_item__txt">Your privacy, our priority</p>
        </div>

      </div>

    </div>
  </div>
  )
}
