import React from 'react';

export default function Whatsapp() {
  return (
    <a href="https://wa.me/message/6GFBB37QHD6UN1" className="whatsapp">
      <svg
        width="176"
        height="175"
        viewBox="0 0 176 175"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_5226_69)">
          <path
            d="M59.4197 86.0243C59.4182 91.3043 60.8086 96.4598 63.4525 101.004L59.1669 116.53L75.18 112.364C79.609 114.756 84.5714 116.01 89.6142 116.01H89.6274C106.275 116.01 119.826 102.569 119.833 86.0479C119.836 78.0422 116.697 70.5143 110.994 64.8506C105.291 59.1875 97.7069 56.067 89.6262 56.0634C72.9771 56.0634 59.4268 69.5039 59.42 86.0243"
            fill="url(#paint0_linear_5226_69)"
          />
          <path
            d="M58.3413 86.0146C58.3396 91.4846 59.7798 96.8245 62.5179 101.531L58.0786 117.614L74.6659 113.299C79.2363 115.771 84.382 117.075 89.6181 117.077H89.6316C106.876 117.077 120.914 103.152 120.921 86.0394C120.924 77.7461 117.672 69.9475 111.765 64.081C105.857 58.2152 98.0017 54.9824 89.6316 54.979C72.3842 54.979 58.3481 68.9018 58.3413 86.0146ZM68.2196 100.721L67.6003 99.7452C64.9967 95.6376 63.6225 90.8907 63.6245 86.0165C63.6299 71.7929 75.2961 60.2208 89.6414 60.2208C96.5885 60.2237 103.117 62.9108 108.028 67.7863C112.938 72.6622 115.64 79.1438 115.638 86.0375C115.632 100.261 103.966 111.835 89.6316 111.835H89.6213C84.954 111.832 80.3765 110.589 76.3845 108.238L75.4345 107.679L65.5913 110.24L68.2196 100.721Z"
            fill="url(#paint1_linear_5226_69)"
          />
          <path
            d="M81.811 73.0397C81.2253 71.748 80.6089 71.722 80.0519 71.6993C79.5958 71.6798 79.0744 71.6813 78.5535 71.6813C78.0321 71.6813 77.185 71.8759 76.4689 72.6517C75.7521 73.4282 73.7323 75.3047 73.7323 79.1213C73.7323 82.938 76.534 86.6267 76.9245 87.1448C77.3156 87.6619 82.3332 95.7447 90.2798 98.8542C96.8842 101.438 98.2282 100.924 99.6616 100.795C101.095 100.666 104.287 98.9188 104.939 97.1073C105.59 95.2961 105.59 93.7435 105.395 93.4191C105.2 93.0958 104.678 92.9017 103.896 92.5139C103.114 92.1261 99.2705 90.2491 98.554 89.9902C97.8372 89.7315 97.316 89.6025 96.7946 90.3792C96.2732 91.1548 94.776 92.9017 94.3197 93.4191C93.8638 93.9376 93.4075 94.0022 92.6259 93.6142C91.8435 93.2249 89.3257 92.4067 86.3384 89.7642C84.0142 87.7079 82.4451 85.1686 81.989 84.3919C81.5329 83.6163 81.9402 83.1959 82.3322 82.8093C82.6835 82.4618 83.1143 81.9035 83.5056 81.4507C83.8954 80.9976 84.0255 80.6744 84.2862 80.157C84.5471 79.6392 84.4166 79.1861 84.2214 78.7981C84.0255 78.4101 82.5062 74.5735 81.811 73.0397Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_5226_69"
            x="0.379547"
            y="0.761028"
            width="178.241"
            height="178.241"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="3.48109" />
            <feGaussianBlur stdDeviation="28.8495" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_5226_69"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_5226_69"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_5226_69"
            x1="3092.47"
            y1="6102.73"
            x2="3092.47"
            y2="56.0634"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1FAF38" />
            <stop offset="1" stopColor="#60D669" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_5226_69"
            x1="3200.22"
            y1="6318.48"
            x2="3200.22"
            y2="54.979"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9F9F9" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </svg>
    </a>
  );
}
