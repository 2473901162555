import React from 'react';

import '../../assets/assets-homepage/css/reset.scss'
import '../../assets/assets-homepage/css/page.scss'
import '../../assets/assets-homepage/fonts/Inter/stylesheet.css'

import Whatsapp from './HomePageComponents/Whatsapp';

import Main from './HomePageComponents/Main';
import Feedback from './HomePageComponents/Feedback';
import ServicesSlider from './HomePageComponents/ServicesSlider';
import CremeSection from './HomePageComponents/CremeSlider';
import WhyUs from './HomePageComponents/WhyUs';
import Founder from './HomePageComponents/Founder';
import BecomeClient from './HomePageComponents/BecomeClient';
import MashaSlider from './HomePageComponents/MashsaSlider';
import ClientsChooseSlider from './HomePageComponents/ClientsChooseSlider';
import Footer from './HomePageComponents/Footer';

export function HomePage() {
  return (
    <div className="homePage wrapper">
      <Whatsapp />
      <Main />
      <Feedback />
      <ServicesSlider />
      <CremeSection />
      <WhyUs />
      <Founder />
      <BecomeClient />
      <MashaSlider />
      <ClientsChooseSlider />
      <Footer />
    </div>
  );
}
