import React, { useContext, useEffect, useState } from 'react';
import { ConfirmationSubscriptionModal } from 'src/components/billing/modal/ConfirmationSubscriptionModal';
import { Button, IconSvg } from 'src/components/common';
import { InputText } from 'src/components/common/form';
import { useHttp, useNotification } from '../../hooks';
import { AuthContext } from '../../contexts';
import { LoadingSpinner } from '../../components/common/ui/loading-spinner/LoadingSpinner';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordConfirmationModal } from './modal/ResetPasswordConfirmationModal';
import { ProfilePlansMobile } from 'src/components/profile/ProfilePlansMobile';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('last name is required'),
});
interface Subscription {
  id: string;
  priceId: string;
  createdDate: string;
  currentPeriodEndDate: string;
  status: string;
  cancel_at_period_end: boolean;
  cancel_at: string;
}

export interface UserNameState {
  firstName: string;
  lastName: string;
}
interface Plan {
  id: string;
  title: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  currentPlan: boolean;
  usd: number;
  savePer: string;
  button: string;
  cancelPlan: any;
  modalDescriptionText: string;
  modalTitle: string;
}
export function Profile() {
  const notify = useNotification();
  const navigate = useNavigate();
  const [managePlan, setManagePlan] = useState(false);
  const [isOpenSubscriptionModal, setIsOpenSubscriptionModal] = useState(false);
  const [isOpenSubscriptionConfirmationModal, setIsOpenSubscriptionConfirmationModal] =
    useState(false);
  const [isOpenAnnualSubscriptionModal, setIsOpenAnnualSubscriptionModal] = useState(false);
  const [finalMessage, setFinalMessage] = useState(false);
  const [editName, setEditName] = useState(false);
  const [isSubscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const { sendRequest, isLoading: isLoadingIntent } = useHttp();
  const { sendRequest: sendSubscriptionRequest, isLoading: isLoadingSubscriptionRequest } =
    useHttp();
  const { accessToken, profile, fetchProfile } = useContext(AuthContext);
  const [prices, setPrices] = useState<any>([]);
  const [currentPlanId, setCurrentPlanId] = useState<string>('');
  const [subscription, setSubscription] = useState<Subscription>();
  const [userName, setUserName] = useState<UserNameState>({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
  });
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [isOpenResetPasswordConfimationModal, setIsOpenResetPasswordConfimationModal] =
    useState(false);
  const getCompanyPaymentMethods = () => {
    sendRequest(
      {
        url: `payment/prices?currencyId=${profile?.account.currency.id}`,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setPrices(data);
      },
    );
  };

  const getSubscription = () => {
    sendSubscriptionRequest(
      {
        url: 'payment/subscription',
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setSubscription(data);
        setSubscriptionLoaded(true);
      },
    );
  };

  useEffect(() => {
    accessToken && profile && profile?.account?.currency && getCompanyPaymentMethods();
    accessToken && profile && profile?.account?.currency && getSubscription();

    if (profile) {
      setUserName({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
      });

      setSubscriptionLoaded(true)
    }
  }, [accessToken, profile]);

  const managePlanHandler = () => {
    setManagePlan(prev => !prev);
  };
  const plans = [
    {
      id: prices[0]?.priceId,
      title: 'BIANNUAL',
      description1: prices[0]?.details[0]?.title || '',
      description2: prices[0]?.details[1]?.title || '',
      description3: prices[0]?.details[2]?.title || '',
      description4: prices[0]?.details[3]?.title || '',
      currentPlan: false,
      usd: prices[0]?.amount || '',
      currency: prices[0]?.currency?.code || '',
      currencyId: prices[0]?.currency?.id,
      savePer: '',
      button: subscription?.priceId == prices[0]?.priceId ? 'Current plan' : 'Choose plan',
      cancelPlan: subscription?.priceId == prices[0]?.priceId,
      modalDescriptionText:
        'Are you sure about switching from a yearly to a biannual subscription?',
      modalTitle: 'Biannual Subscription',
    },
    {
      id: prices[1]?.priceId,
      title: 'YEARLY',
      description1: prices[1]?.details[0]?.title || '',
      description2: prices[1]?.details[1]?.title || '',
      description3: prices[1]?.details[2]?.title || '',
      description4: prices[1]?.details[3]?.title || '',
      currentPlan: true,
      usd: prices[1]?.amount || '',
      currency: prices[1]?.currency?.code || '',
      currencyId: prices[1]?.currency?.id,
      savePer: '25',
      button: subscription?.priceId == prices[1]?.priceId ? 'Current plan' : 'Choose plan',
      cancelPlan: subscription?.priceId == prices[1]?.priceId,
      modalDescriptionText:
        'Yay! Switching to an yearly subscription will save you 25% in membership costs. Ready?',
      modalTitle: 'Annual Subscription',
    },
  ];

  const updateSubscription = () => {
    sendRequest(
      {
        url: `payment/update-subscription`,
        method: 'POST',
        payload: { priceId: currentPlanId },
        accessToken,
      },
      (response: any) => {
        setFinalMessage(true);
        fetchProfile();
        getSubscription();
        notify(`${response.message}`, 'success');
      },
    );
  };

  const onSubmitConfirmationHandler = (title: string) => {
    cancelSubscription();
    setIsOpenSubscriptionConfirmationModal(false);
  };
  const onSubmitHandler = (title: string) => {
    if (!finalMessage) {
      updateSubscription();
    } else {
      setFinalMessage(false);
      setIsOpenSubscriptionModal(false);
      setManagePlan(prev => !prev);
    }
  };

  const subscriptionModalHandler = (planId: string) => {
    setCurrentPlanId(planId);
    setIsOpenSubscriptionModal(prev => !prev);
  };

  const subscriptionConfirmationModalHandler = (planId: string) => {
    setCurrentPlanId(planId);
    setIsOpenSubscriptionConfirmationModal(prev => !prev);
  };

  const resumeSubscriptionModalHandler = (priceId: string) => {
    console.log('resume', priceId);
    resumeSubscriptionHandler(priceId);
  };
  const resumeSubscriptionHandler = (priceId: string) => {
    sendRequest(
      {
        url: `payment/resume-subscription`,
        method: 'POST',
        payload: { priceId: priceId },
        accessToken,
      },
      (response: any) => {
        fetchProfile();
        getSubscription();
        setManagePlan(prev => !prev);
        notify(`${response.message}`, 'success');
      },
    );
  };
  const cancelSubscriptionModalHandler = () => {
    setIsOpenSubscriptionModal(prev => !prev);
  };

  const cancelSubscriptionConfirmationModalHandler = () => {
    setIsOpenSubscriptionConfirmationModal(prev => !prev);
  };
  const formik = useFormik({
    initialValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      await sendRequest(
        {
          url: 'user/name',
          method: 'POST',
          payload: { ...values },
          accessToken,
        },
        (data: any) => {
          setUserName({
            firstName: data?.firstName,
            lastName: data?.lastName,
          });
          setEditName(false);
          fetchProfile();
        },
      );
    },
  });

  const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;

  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
    formik.setFieldTouched(name, true, true);
    console.log('this is error', errors);
    console.log('this is touch', touched);
  };

  const createSubscription = (priceId: string) => {
    sendRequest(
      {
        url: `payment/create-subscription`,
        method: 'POST',
        payload: { priceId: priceId },
        accessToken,
      },
      (response: any) => {
        fetchProfile();
        getSubscription();
        setManagePlan(prev => !prev);
        notify(`${response.message}`, 'success');
      },
    );
  };

  const cancelSubscription = () => {
    sendRequest(
      {
        url: `payment/cancel-subscription`,
        method: 'POST',
        payload: {},
        accessToken,
      },
      (response: any) => {
        fetchProfile();
        getSubscription();
        setManagePlan(prev => !prev);
        notify(`${response.message}`, 'success');
      },
    );
  };

  const nameEditHandler = () => {
    setEditName(true);
    setFieldValue('firstName', profile?.firstName);
    setFieldValue('lastName', profile?.lastName);
  };

  const handleButtonClick = () => {
    window.open(
      'https://api.whatsapp.com/message/6GFBB37QHD6UN1?autoload=1&app_absent=0',
      '_blank',
    );
  };
  const resetPasswordHandler = () => {
    sendRequest(
      {
        url: `auth/generate-reset-password-link`,
        method: 'POST',
        payload: {
          email: profile?.email,
        },
      },
      (response: any) => {
        console.log({ response });
        resetPasswordConfirmationModalHandler();
      },
    );
  };

  const resetPasswordConfirmationModalHandler = () => {
    setIsOpenResetPasswordConfimationModal(prev => !prev);
  };

  return (
    <>
      {!isSubscriptionLoaded ? (
        <>
          <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
            Current plan
          </h2>
          <div className="border border-[#959595] border-solid rounded-[5px] py-4 px-6 mt-4 min-h-52"></div>
        </>
      ) : (
        <div>
          {!managePlan && (
            <>
              {profile?.account?.defaultStripePaymentMethod ? (
                <>
                  <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
                    Current plan
                  </h2>
                  <div className="border border-[#959595] border-solid rounded-[5px] lg:p-7 sm:p-6 p-4 sm:mt-4 mt-2">
                    {!subscription ? (
                      <div className="flex justify-between items-center">
                        <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] uppercase">
                          You&apos;ve not purchased any subscription yet{' '}
                        </p>
                        <Button
                          size="small"
                          color="yellow"
                          label={
                            <p className="uppercase text-[#0F0F0F] font-medium font-inter text-lg leading-[21.6px]">
                              PURCHASE A PLAN
                            </p>
                          }
                          className="px-8"
                          onClick={() => managePlanHandler()}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-between items-center">
                          <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] uppercase">
                            You&apos;ve purchased a {profile?.account?.plan?.name}{' '}
                            <br className="sm:hidden block" />
                            subscription on{' '}
                            <span className="font-bold font-inter">
                              {subscription?.createdDate}
                            </span>
                          </p>
                          <Button
                            size="small"
                            color="yellow"
                            label={
                              <p className="uppercase text-[#0F0F0F] font-medium font-inter text-lg leading-[21.6px]">
                                MANAGE PLAN
                              </p>
                            }
                            className="px-8 lg:block hidden"
                            onClick={() => managePlanHandler()}
                          />
                        </div>
                        <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg text-base lg:leading-6 sm:leading-[21.6px] leading-[19.2px] lg:pt-2 sm:pt-5 pt-2 lg:my-2 sm:my-0 my-2">
                          Your subscription will
                          <br className="sm:hidden block" /> automatically{' '}
                          {subscription?.cancel_at_period_end ? `cancelled` : `renew`} on
                          <br className="sm:hidden block" />{' '}
                          <span className="font-bold font-inter">
                            {subscription?.cancel_at_period_end
                              ? subscription?.cancel_at
                              : subscription?.currentPeriodEndDate}
                          </span>
                        </p>

                        <div className="lg:pt-9 sm:pt-10 pt-9 flex flex-col lg:space-y-5 sm:space-y-5 space-y-8">
                          {profile?.account?.plan?.details?.map((item: any) => {
                            return (
                              <div
                                className="flex sm:items-start items-center space-x-2 "
                                key={item.id}
                              >
                                <IconSvg
                                  icon={'oDotSmallOrange'}
                                  className="lg:w-auto lg:h-auto w-[15px] h-[15px]"
                                />
                                <p
                                  className={`text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-base text-sm lg:leading-6 sm:leading-[19.2px] leading-[16.8px]`}
                                >
                                  {item.title}
                                </p>
                              </div>
                            );
                          })}

                          <Button
                            size="small"
                            color="yellow"
                            label={
                              <p className="uppercase text-[#0F0F0F] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                                MANAGE PLAN
                              </p>
                            }
                            className="sm:px-8 px-0 lg:hidden sm:w-[200px] w-[108px] rounded-[5px]"
                            onClick={() => managePlanHandler()}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
                    Current plan
                  </h2>
                  <div className="border border-[#959595] border-solid rounded-[5px] p-7 mt-4">
                    <div className="flex items-center justify-between">
                      <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-base text-sm lg:leading-6 sm:leading-[19.2px] leading-[16.8px] uppercase">
                        PLEASE ADD A BILLING METHOD TO PURCHASE A MEMBERSHIP PLAN.
                      </p>
                      <Button
                        size="small"
                        color="yellow"
                        label={
                          <p className="uppercase text-[#0F0F0F] text-center font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                            Add billing method
                          </p>
                        }
                        className="px-8"
                        onClick={() => navigate('/profile/billing')}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {managePlan && (
            <>
              <div className="flex items-start justify-between">
                <div>
                  <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
                    Plan
                  </h2>
                  <p className="text-[#4A4A4A] font-light font-inter lg:text-xl sm:text-lg  lg:leading-6 sm:leading-[21.6px] text-[12px] leading-[14.4px] pt-3">
                    Your subscription will automatically{' '}
                    {subscription?.cancel_at_period_end ? `cancelled` : `renew`} on on{' '}
                    <span className="font-bold font-inter">
                      {subscription?.cancel_at_period_end
                        ? subscription?.cancel_at
                        : subscription?.currentPeriodEndDate}
                    </span>
                  </p>
                </div>
                <Button
                  size="small"
                  color="primary"
                  label={
                    <p className="uppercase text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-sm  lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                      Back
                    </p>
                  }
                  className="lg:px-8 sm:px-6 px-4"
                  onClick={() => managePlanHandler()}
                />
              </div>
              <div className="lg:block hidden">
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 mt-6 w-full">
                  {plans.map((plan, i) => (
                    <div
                      className={`${'border border-[#C56B48] rounded-[5px]'} flex flex-col justify-between  w-full relative`}
                      key={plan.id}
                    >
                      <div>
                        <div
                          className={`flex justify-center items-center ${
                            i == 2 ? '!bg-[#4A4A4A] border-white border-b' : 'bg-[#C56B48]'
                          }`}
                        >
                          <p className="uppercase py-6 text-[#EFE8DF] text-2xl font-medium font-inter leading-[28.8px]">
                            {plan.title}
                          </p>
                        </div>
                        <div className="p-3 flex flex-col">
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              {plan.description1}
                            </p>
                          </div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              {plan.description2}
                            </p>
                          </div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              {plan.description3}
                            </p>
                          </div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={i == 2 ? 'oDotWhiteSmall' : 'oDotSmallOrange'} />
                            <p
                              className={`text-[#4A4A4A]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              {plan.description4}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center justify-center p-3 mt-4">
                        <div className="flex items-baseline gap-2">
                          <h1 className="pb-5 text-[#0F0F0F]  text-[28px] font-inter font-light leading-[33.6px]">
                            {plan.usd} {plan.currency}
                          </h1>
                          {plan.savePer && (
                            <p className="text-[#EFE8DF]  text-base font-inter font-bold leading-[19.2px] bg-[#4A4A4A] px-2.5 py-1.5 rounded-[10px]">{`(SAVE ${plan.savePer}%)`}</p>
                          )}
                        </div>

                        {!subscription ? (
                          <Button
                            size="small"
                            color="primary"
                            label={
                              <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                                {plan.button}
                              </p>
                            }
                            className="px-16"
                            onClick={() => {
                              createSubscription(plan.id);
                              setSelectedPlan(plan);
                            }}
                          />
                        ) : null}

                        {subscription && !subscription.cancel_at_period_end && !plan.cancelPlan ? (
                          <Button
                            size="small"
                            color="primary"
                            label={
                              <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                                {plan.button}
                              </p>
                            }
                            className="px-16"
                            onClick={() => {
                              subscriptionModalHandler(plan.id);
                              setSelectedPlan(plan);
                            }}
                          />
                        ) : null}

                        {subscription && subscription.cancel_at_period_end && !plan.cancelPlan ? (
                          <Button
                            size="small"
                            color="primary"
                            isDisabled={true}
                            label={
                              <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                                {plan.button}
                              </p>
                            }
                            className="px-16"
                            onClick={() => {
                              console.log('');
                              // subscriptionModalHandler(plan.id);
                              // setSelectedPlan(plan);
                            }}
                          />
                        ) : null}

                        {plan.cancelPlan ? (
                          <Button
                            size="small"
                            color="onlyBorder"
                            label={
                              <p className="uppercase font-medium font-inter text-lg leading-[21.6px]">
                                {plan.button}
                              </p>
                            }
                            className="px-16"
                            onClick={() => console.log('')}
                          />
                        ) : null}

                        {plan.cancelPlan ? (
                          subscription?.cancel_at_period_end ? (
                            <p
                              className="uppercase font-medium text-sm leading-4 font-inter text-green-600 p-3 cursor-pointer"
                              onClick={() => resumeSubscriptionModalHandler(plan.id)}
                            >
                              resume plan
                            </p>
                          ) : (
                            <p
                              className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer"
                              onClick={() => subscriptionConfirmationModalHandler(plan.id)}
                            >
                              cancel plan
                            </p>
                          )
                        ) : (
                          <p className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer">
                            &nbsp;
                          </p>
                        )}
                      </div>
                      {i == 1 && (
                        <div className="absolute -top-5 right-4 flex items-center gap-2 bg-[#9B2626] py-2 px-3 rounded-[5px]">
                          <IconSvg icon="starCream" />
                          <p className="font-light font-inter text-sm text-[#EFE8DF] uppercase">
                            BEST VALUE{' '}
                          </p>
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className={`${'border border-[#4A4A4A] bg-[#4A4A4A] rounded-[5px]'} flex flex-col justify-between w-full `}
                  >
                    <div>
                      <div
                        className={`flex justify-center items-center ${'!bg-[#4A4A4A] border-white border-b'}`}
                      >
                        <p className="uppercase py-6 text-[#EFE8DF] text-2xl font-medium font-inter leading-[28.8px]">
                          Enterprise
                        </p>
                      </div>
                      <div className="p-6 flex flex-col justify-between">
                        <div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              Include XPLORIUS in your employee benefits scheme
                            </p>
                          </div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              Unlock unlimited discounted business trips
                            </p>
                          </div>
                          <div className="flex items-start gap-4 pt-4">
                            <IconSvg icon={'oDotSmallOrange'} />
                            <p
                              className={`text-[#EFE8DF]  text-base font-inter font-light leading-[19.2px]`}
                            >
                              Receive dedicated B2B terms
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="p-3 flex flex-col items-center justify-end h-full">
                      <Button
                        size="small"
                        color="orange"
                        label={
                          <p className="uppercase text-white font-medium font-inter text-lg leading-[21.6px]">
                            Talk to sales
                          </p>
                        }
                        className="px-14"
                        startIcon
                        icon={<IconSvg icon="headPhone" />}
                        onClick={handleButtonClick}
                      />
                    </div>
                    <p className="uppercase font-medium text-sm leading-4 font-inter text-[#9B2626] p-3 cursor-pointer">
                      &nbsp;
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:hidden block mb-[64px]">
                <ProfilePlansMobile
                  plans={plans}
                  subscription={subscription}
                  createSubscription={createSubscription}
                  setSelectedPlan={setSelectedPlan}
                  subscriptionModalHandler={subscriptionModalHandler}
                  resumeSubscriptionModalHandler={resumeSubscriptionModalHandler}
                  subscriptionConfirmationModalHandler={subscriptionConfirmationModalHandler}
                  handleButtonClick={handleButtonClick}
                />
              </div>
            </>
          )}
        </div>
      )}

      <div className="lg:my-[50px] my-10">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Personal info
        </h2>
        <div className="sm:flex items-center justify-between border border-[#959595] border-solid rounded-[5px] lg:p-7 sm:p-6 p-4 lg:mt-4 sm:mt-3 mt-4">
          {!editName && (
            <>
              <div>
                <p className="text-[#0F0F0F] font-bold font-inter sm:text-xl text-lg sm:leading-6 leading-[21.6px]">
                  Name
                </p>
                <p className="text-[#4A4A4A] font-light font-inter sm:text-xl text-base sm:leading-6 leading-[19.2px] lg:mt-2 mt-2.5">
                  {userName.firstName} {userName.lastName}
                </p>
              </div>
              <div
                className="bg-[#4A4A4A] lg:px-4 sm:px-0 px-4 py-2 rounded-[5px] cursor-pointer lg:w-auto sm:w-[79px] w-[60px] sm:mt-0 mt-3 flex justify-center"
                onClick={() => nameEditHandler()}
              >
                <p className="text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px]">
                  EDIT
                </p>
              </div>
            </>
          )}
          {editName && (
            <form onSubmit={handleSubmit} className="w-full">
              <div className="w-full">
                <div className="flex items-start w-full gap-4">
                  <InputText
                    id={'firstName'}
                    name={'firstName'}
                    label={'First name'}
                    placeholder={'Enter first name'}
                    value={values['firstName' as keyof UserNameState]}
                    onChange={onChangeHandler}
                    isDisabled={false}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={touched.firstName && !!errors.firstName}
                    errorMessage={errors.firstName as keyof UserNameState}
                    wrapperStyles="w-full"
                  />
                  <InputText
                    id={'lastName'}
                    name={'lastName'}
                    label={'Last name'}
                    placeholder={'Enter last name'}
                    value={values['lastName' as keyof UserNameState]}
                    onChange={onChangeHandler}
                    isDisabled={false}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={touched.lastName && !!errors.lastName}
                    errorMessage={errors.lastName as keyof UserNameState}
                    wrapperStyles="w-full"
                  />
                </div>
                <div className="flex w-full gap-4">
                  <div className="w-full sm:block hidden"></div>
                  <div className="flex items-center gap-4 justify-end mt-6 w-full">
                    <Button
                      size="small"
                      color="primary"
                      label={
                        <p className="uppercase text-[#4A4A4A] font-medium font-inter sm:text-lg text-[12px]  sm:leading-[21.6px] leading-[14.4px]">
                          Cancel
                        </p>
                      }
                      className="px-8 !bg-transparent border-2 rounded-[5px] border-[#4A4A4A] w-full"
                      onClick={() => setEditName(false)}
                    />
                    <Button
                      size="small"
                      color="primary"
                      type="submit"
                      label={
                        <p className="uppercase text-[#FFFFFF] font-medium font-inter sm:text-lg text-[12px]  sm:leading-[21.6px] leading-[14.4px]">
                          Save
                        </p>
                      }
                      className="px-10 w-full"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>

      <div className="">
        <h2 className="text-[#0F0F0F] font-medium font-inter lg:text-3xl sm:text-2xl text-xl lg:leading-[35.4px] sm:leading-[28.32px] leading-[23.6px] uppercase">
          Login & password
        </h2>
        <div className=" border border-[#959595] border-solid rounded-[5px] lg:p-7 sm:p-6 p-4 lg:mt-4 sm:mt-3 mt-4">
          {profile?.account?.isGoogleSignup && (
            <div>
              <p className="text-[#0F0F0F] font-bold font-inter sm:text-xl text-lg sm:leading-6 leading-[21.6px]">
                Email
              </p>
              <p className="text-[#4A4A4A] font-light !font-inter sm:text-xl text-base sm:leading-6 leading-[19.2px] mt-2">
                Your email and password cannot be changed because you signed up with Google or
                Apple.
              </p>
            </div>
          )}
          <div className="lg:mt-8 sm:mt-0 mt-6">
            <p className="text-[#0F0F0F] font-bold font-inter sm:text-xl text-lg sm:leading-6 leading-[21.6px]">
              Email
            </p>
            <p className="text-[#4A4A4A] font-light !font-inter sm:text-xl text-base sm:leading-6 leading-[19.2px] mt-2">
              {profile?.email}
            </p>
          </div>
          <div className="lg:mt-8 mt-6 sm:flex justify-between items-center">
            <div>
              <p className="text-[#0F0F0F] font-bold font-inter sm:text-xl text-lg sm:leading-6 leading-[21.6px]">
                Password
              </p>
              <p className="text-[#4A4A4A] font-light font-inter sm:text-xl text-base sm:leading-6 leading-[19.2px] mt-2 ">
                **********
              </p>
            </div>
            {!profile?.account?.isGoogleSignup && (
              <Button
                size="small"
                color="primary"
                type="submit"
                label={
                  <p className="uppercase text-[#FFFFFF] font-medium font-inter lg:text-lg sm:text-base text-[12px] lg:leading-[21.6px] sm:leading-[19.2px] leading-[14.4px] ">
                    Reset my password
                  </p>
                }
                className="sm:px-6 px-0 sm:mt-0 mt-3 sm:w-auto w-[150px] rounded-[5px]"
                onClick={resetPasswordHandler}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmationSubscriptionModal
        isOpen={isOpenSubscriptionModal}
        onCancel={cancelSubscriptionModalHandler}
        onSubmitHandler={onSubmitHandler}
        title={finalMessage ? '' : selectedPlan?.modalTitle || ''}
        description={
          finalMessage
            ? 'Noted, changes will be made shortly. If you have any queries, please get in touch with us via Help desk.'
            : selectedPlan?.modalDescriptionText || ''
        }
        cancelButton={!finalMessage}
        yesButtonText={finalMessage ? 'DONE' : 'YES, PLEASE'}
      />

      <ConfirmationSubscriptionModal
        isOpen={isOpenSubscriptionConfirmationModal}
        onCancel={cancelSubscriptionConfirmationModalHandler}
        onSubmitHandler={onSubmitConfirmationHandler}
        title={'Cancel Membership Subscription'}
        description={`Are you sure you want to cancel your membership subscription?`}
        cancelButton={true}
        yesButtonText={'YES, PLEASE'}
      />
      <ResetPasswordConfirmationModal
        isOpen={isOpenResetPasswordConfimationModal}
        onCancel={resetPasswordConfirmationModalHandler}
      />
      {(isLoadingIntent || !isSubscriptionLoaded) && <LoadingSpinner />}
    </>
  );
}
