import { useContext } from 'react';
import { IconSvg } from './IconSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

export function Footer() {
  const navigate = useNavigate();
  const { loginWithRedirect, userAuth0, accessToken } = useContext(AuthContext);
  const { pathname } = useLocation();

  // const signupHandler = () => {
  //   if (accessToken) {
  //     navigate('/profile');
  //   } else {
  //     // void loginWithRedirect({
  //     //   appState: { returnTo: `/sign-up` },
  //     //   screen_hint: 'signup',
  //     // });
  //     navigate('/sign-up');
  //   }
  // };
  return (
    <>
      <div className="flex">
        <div className="w-full lg:block hidden">
          <IconSvg
            onClick={() => navigate('/')}
            icon="logoBlack"
            className="w-[257px] cursor-pointer"
          />
        </div>
        <div className="w-full sm:flex">
          <div className="w-full mt-2">
            <p
              className="font-medium  lg:text-lg text-sm cursor-pointer leading-[21.6px] font-inter"
              onClick={() => navigate('/about-us')}
            >
              About us
            </p>
            <p
              className="mt-[10px] lg:text-lg text-sm font-medium font-inter leading-[21.6px] cursor-pointer"
              onClick={() => navigate('/terms-use')}
            >
              Terms of use (EULA)
            </p>
            <p
              className="mt-[10px] lg:text-lg text-sm font-medium font-inter leading-[21.6px] cursor-pointer"
              onClick={() => navigate('/terms-sale')}
            >
              Terms of sale
            </p>
            <p
              className="mt-[10px] lg:text-lg text-sm font-medium font-inter leading-[21.6px] cursor-pointer"
              onClick={() => navigate('/privacy-policy')}
            >
              Privacy policy
            </p>
            <p
              className="mt-[10px] lg:text-lg text-sm font-medium font-inter leading-[21.6px] cursor-pointer"
              onClick={() => navigate('/cookie-policy')}
            >
              Cookie policy
            </p>
          </div>
          <div className="w-full sm:mt-0 mt-8">
            <p className="font-bold lg:text-2xl text-base leading-[28.8px] font-inter uppercase">
              Contact us
            </p>
            <a
              href="mailto:hi@xplorius.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p className="lg:mt-5 mt-2.5 font-medium text-[#4A4A4A] lg:text-lg sm:text-sm font-inter leading-[21.6px]">
                Email: hi@xplorius.com
              </p>
            </a>
            <a
              href="https://api.whatsapp.com/message/6GFBB37QHD6UN1?autoload=1&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <p className="font-medium text-[#4A4A4A] lg:text-lg text-sm mt-2.5 font-inter leading-[21.6px]">
                WhatsApp: +971 58 565 7715
              </p>
            </a>
            <div className="flex items-center lg:gap-[35px] gap-[15px] lg:mt-[35px] mt-5">
              <a
                href="https://www.instagram.com/travelwithxplorius/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconSvg
                  icon="instagram"
                  className="lg:w-[30px] lg:h-[30px] w-[22.5px] h-[22.5px]"
                />
              </a>
              <a
                href="https://discord.com/invite/xploriusxyz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconSvg icon="game" className="lg:w-[30px] lg:h-[26px] w-[22.5px] h-[22.5px]" />
              </a>
              <a href="https://x.com/XPLORIUSxyz" target="_blank" rel="noopener noreferrer">
                <IconSvg
                  icon="twitter"
                  className="lg:w-[32.25px] lg:h-[26.65px] w-[24.19px] h-[19.99px]"
                />
              </a>
            </div>
            <div className="w-full lg:hidden block mt-[43px] ">
              <div className="flex sm:justify-start justify-center items-center pb-6">
                <IconSvg
                  onClick={() => navigate('/')}
                  icon="logoBlack"
                  className="w-[123px] cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {pathname == '/' && (
        <>
          <div className="">
            <div className="fixed bottom-10 right-10 z-50 sm:block hidden">
              <p
                className="bg-[#E2BA13] h-[87px] uppercase w-[87px] text-black rounded-full text-center flex items-center justify-center cursor-pointer font-medium font-inter neon-button"
                onClick={signupHandler}
              >
                Sign up
              </p>
            </div>
          </div>

          <div className="fixed bottom-10 right-10 z-50 sm:hidden block">
            <p
              className="bg-[#E2BA13] h-[70px] w-[70px] uppercase text-[#0F0F0F] text-sm font-medium font-inter rounded-full text-center flex items-center justify-center cursor-pointer neon-button"
              onClick={signupHandler}
            >
              Sign up
            </p>
          </div>
        </>
      )} */}
    </>
  );
}
