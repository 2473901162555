import React from 'react';
import { Modal } from './Modal';
import { IconSvg } from '../ui/IconSvg';

import hot1 from '../../../assets/assets-wowsummit/img/hot-1.png';
import hot2 from '../../../assets/assets-wowsummit/img/hot-2.png';
import hot3 from '../../../assets/assets-wowsummit/img/hot-3.png';

interface AboutHotelModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AboutHotelModal: React.FC<AboutHotelModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      close={onClose}
      onSubmit={() => {
        console.log('');
      }}
      modalWidth="w-[1000px] bg-white p-6 sm:p-10 rounded-[30px]"
      withCrossBtn={false}
    >
      <div className="relative">
        <div
          className="text-black flex items-center mb-2 justify-end cursor-pointer"
          onClick={() => onClose()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            className="cursor-pointer"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.6346 8.60204C25.7487 8.48816 25.8391 8.35293 25.9009 8.20408C25.9627 8.05524 25.9945 7.89568 25.9946 7.73453C25.9947 7.57338 25.9631 7.41378 25.9015 7.26486C25.8399 7.11593 25.7496 6.9806 25.6357 6.86657C25.5218 6.75255 25.3866 6.66208 25.2378 6.60031C25.0889 6.53855 24.9293 6.50671 24.7682 6.50661C24.607 6.50651 24.4474 6.53816 24.2985 6.59974C24.1496 6.66132 24.0143 6.75162 23.9002 6.86551L16.4997 14.266L9.10131 6.86551C8.87103 6.63523 8.5587 6.50586 8.23304 6.50586C7.90738 6.50586 7.59505 6.63523 7.36477 6.86551C7.1345 7.09579 7.00513 7.40811 7.00513 7.73377C7.00513 8.05944 7.1345 8.37176 7.36477 8.60204L14.7653 16.0004L7.36477 23.3988C7.25075 23.5129 7.1603 23.6482 7.0986 23.7972C7.03689 23.9462 7.00513 24.1059 7.00513 24.2671C7.00513 24.4284 7.03689 24.588 7.0986 24.737C7.1603 24.886 7.25075 25.0214 7.36477 25.1354C7.59505 25.3657 7.90738 25.495 8.23304 25.495C8.39429 25.495 8.55397 25.4633 8.70294 25.4016C8.85192 25.3398 8.98729 25.2494 9.10131 25.1354L16.4997 17.7348L23.9002 25.1354C24.1305 25.3654 24.4427 25.4945 24.7682 25.4943C25.0937 25.4941 25.4057 25.3646 25.6357 25.1343C25.8657 24.904 25.9948 24.5918 25.9946 24.2664C25.9944 23.9409 25.8649 23.6288 25.6346 23.3988L18.2341 16.0004L25.6346 8.60204Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="flex flex-col sm:flex-row sm:space-x-10 gap-6">
          {/* Left Text Column */}
          <div className="lg:w-1/2 w-full text-[#0F0F0F] space-y-5 font-inter text-[16px] leading-relaxed">
            <p>
              <strong>The Peninsula Hong Kong</strong> is more than a luxury hotel — it&apos;s an
              icon of timeless elegance and heritage. Known as the
              <strong> &quot;Grande Dame of the Far East&quot;</strong>, it has welcomed
              distinguished guests since
              <strong> 1928</strong>.
            </p>

            <p>
              Strategically located in <strong>Tsim Sha Tsui</strong> on the{' '}
              <strong>Kowloon Peninsula</strong>, it was envisioned as{' '}
              <em>&quot;the finest hotel east of the Suez.&quot;</em>
            </p>

            <p>
              The hotel&apos;s <strong>signature fleet of Rolls-Royce cars</strong> in{' '}
              <strong>&quot;Peninsula green&quot;</strong> is a symbol of its legacy, embodying a
              commitment to luxury and impeccable service.
            </p>

            <p>
              The Peninsula is not just a place to stay—it&apos;s where history, culture, and luxury
              converge.
            </p>
          </div>

          {/* Right Image Column */}
          <div className="flex flex-col gap-3 lg:w-1/2 w-full">
            <img src={hot1} alt="Main Hotel" className="rounded-xl w-full h-auto object-cover" />
            <div className="flex space-x-2 w-full">
              <img src={hot2} alt="Hotel Room" className="rounded-xl w-1/2 h-auto object-cover" />
              <img src={hot3} alt="Hotel Lobby" className="rounded-xl w-1/2 h-auto object-cover" />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
