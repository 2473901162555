import { ReactNode, useMemo } from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';

interface Props {
  size: 'extra-small' | 'small' | 'medium';
  color: 'primary' | 'secondary' | 'ghost' | 'disabled' | 'error' | 'gray' | 'white' | 'yellow' | 'orange' | 'red' | 'onlyBorder';

  type?: 'button' | 'submit' | 'reset';
  label?: string | JSX.Element;
  icon?: ReactNode;
  startIcon?: boolean;
  endIcon?: boolean;
  isDisabled?: boolean;
  onClick?: (value: any) => void;
  className?: string;
}

export function Button({
  size,
  color,

  type = 'button',
  label,
  icon,
  startIcon,
  isDisabled,
  endIcon,
  onClick,
  className = '',
}: Props) {
  const sizeBtn = useMemo(() => {
    switch (size) {
      case 'extra-small':
        return styles['button-xs'];

      case 'medium':
        return styles['button-m'];

      default:
        return styles['button-s'];
    }
  }, [size]);

  color = isDisabled ? 'gray' : color;

  const colorBtn = useMemo(() => {
    switch (color) {
      case 'secondary':
        return styles['secondary'];

      case 'ghost':
        return styles['ghost'];
      case 'yellow':
        return styles['yellow'];
        case 'red':
          return styles['red'];
      case 'orange':
        return styles['orange'];

      case 'disabled':
        return styles['disabled'];

      case 'error':
        return styles['error'];

      case 'gray':
        return styles['gray'];
      
        case 'onlyBorder':
        return styles['onlyBorder'];

      default:
        return styles['primary'];
    }
  }, [color]);

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        styles['button'],
        sizeBtn,
        colorBtn,
        { [styles['disabled']]: isDisabled },
        className,
      )}
    >
      {startIcon && icon && (
        <span className={clsx(styles['button'], styles['icon-start'])}>{icon}</span>
      )}
      <span className={clsx(styles['button-label'])}>{label}</span>
      {endIcon && icon && (
        <span className={clsx(styles['button'], styles['icon-end'])}>{icon}</span>
      )}

      {icon && !label && <span>{icon}</span>}
    </button>
  );
}
