import mainimg from '../../../assets/assets-homepage/img/bgmain.webp';
import mainimg2 from '../../../assets/assets-homepage/img/mainsecbg2.webp';
import logo from '../../../assets/assets-homepage/img/logo.svg';
import logoMob from '../../../assets/assets-homepage/img/logo_mobmob.svg';
import bitcoin from '../../../assets/assets-homepage/img/icons/logos_bitcoin.svg';
import { useState } from 'react';

export default function Main() {
  const [menu, setMenu] = useState(false)
  return (
    <div className="main_section">
      <img src={mainimg} className="mainimg mainimg_desc"  />
      <img
        src={mainimg2}
        className="mainimg mainimg_mob"
      />

      <header className="header">
        <div className="container">
          <a href="/" className="logo">
            <img src={logo} className="logo_desc"  />
            <img src={logoMob} className="logo_mob"  />
          </a>

          <div className={`burger-menu ${menu?'active':''}`}  onClick={()=>setMenu((prevState) => !prevState)}>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <nav className={`main_menu ${menu?'main_menu_show':''}`}>
            <a href="/" className="main_menu_logo" onClick={()=>setMenu(false)}>
              <img src={logo} />
            </a>

                   <ul>
              <li>
                <a href="#services" onClick={()=>setMenu(false)}>Services</a>
              </li>
              <li>
                <a href="#why_us" onClick={()=>setMenu(false)}>Why us?</a>
              </li>
              <li>
                <a href="#become" onClick={()=>setMenu(false)}>Become a client</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <div className="main_section__wrap">
        <div className="main_section__content">
          <div className="container">
            <div className="flxcol">
              <h1 className="title">
                White-glove <br /> <span>luxury travel</span> management
                company.
              </h1>

              <a href="https://wa.me/message/6GFBB37QHD6UN1" className="speek">
                Speak to an expert
              </a>
            </div>

            <div className="main_section__bottom">
              <div className="main_section__lblsss">
                <div className="main_section__lbl">
                  <span>Based in Dubai, loved globally.</span>
                </div>
                <div className="main_section__lbl">
                  <span>We accept crypto</span>{' '}
                  <img src={bitcoin}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
