import React, { useState } from 'react';
import { Modal } from './Modal';
import { Button } from '../ui/button/Button';
import { IconSvg } from '../ui/IconSvg';
import { useNavigate } from 'react-router-dom';

interface UserInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { name: string; email: string; password: string }) => void;
}

export const UserInfoModal: React.FC<UserInfoModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState<string[]>([]);

  const passwordRules =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePassword = (pass: string) => {
    const errors: string[] = [];

    if (pass.length < 8) {
      errors.push('At least 8 characters');
    }
    if (!/[a-z]/.test(pass)) {
      errors.push('One lowercase letter');
    }
    if (!/[A-Z]/.test(pass)) {
      errors.push('One uppercase letter');
    }
    if (!/\d/.test(pass)) {
      errors.push('One number');
    }
    if (!/[!@#$%^&*()_+]/.test(pass)) {
      errors.push('One special character (!@#$%^&*()_+)');
    }

    setPasswordErrors(errors);
    return errors.length === 0;
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
    if (value.trim() !== '') {
      validatePassword(value);
    } else {
      setPasswordErrors([]);
    }
  };

  const isFormValid =
    name.trim() !== '' &&
    surname.trim() !== '' &&
    email.trim() !== '' &&
    validateEmail(email) &&
    password.trim() !== '' &&
    passwordErrors.length === 0;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isFormValid) {
      onSubmit({ name: `${name} ${surname}`, email, password });
      onClose();
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal
      isOpen={isOpen}
      close={onClose}
      onSubmit={handleSubmit}
      modalWidth="w-[800px] bg-white rounded-[30px]"
      withCrossBtn={false}
    >
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <p className="font-inter text-[#0F0F0F] lg:text-2xl text-sm lg:leading-10 leading-5 font-semibold">
              Our exclusive rates are too <br className="lg:hidden block" /> good to be public
            </p>
            <div className="text-black cursor-pointer" onClick={() => onClose()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M25.6346 8.60204C25.7487 8.48816 25.8391 8.35293 25.9009 8.20408C25.9627 8.05524 25.9945 7.89568 25.9946 7.73453C25.9947 7.57338 25.9631 7.41378 25.9015 7.26486C25.8399 7.11593 25.7496 6.9806 25.6357 6.86657C25.5218 6.75255 25.3866 6.66208 25.2378 6.60031C25.0889 6.53855 24.9293 6.50671 24.7682 6.50661C24.607 6.50651 24.4474 6.53816 24.2985 6.59974C24.1496 6.66132 24.0143 6.75162 23.9002 6.86551L16.4997 14.266L9.10131 6.86551C8.87103 6.63523 8.5587 6.50586 8.23304 6.50586C7.90738 6.50586 7.59505 6.63523 7.36477 6.86551C7.1345 7.09579 7.00513 7.40811 7.00513 7.73377C7.00513 8.05944 7.1345 8.37176 7.36477 8.60204L14.7653 16.0004L7.36477 23.3988C7.25075 23.5129 7.1603 23.6482 7.0986 23.7972C7.03689 23.9462 7.00513 24.1059 7.00513 24.2671C7.00513 24.4284 7.03689 24.588 7.0986 24.737C7.1603 24.886 7.25075 25.0214 7.36477 25.1354C7.59505 25.3657 7.90738 25.495 8.23304 25.495C8.39429 25.495 8.55397 25.4633 8.70294 25.4016C8.85192 25.3398 8.98729 25.2494 9.10131 25.1354L16.4997 17.7348L23.9002 25.1354C24.1305 25.3654 24.4427 25.4945 24.7682 25.4943C25.0937 25.4941 25.4057 25.3646 25.6357 25.1343C25.8657 24.904 25.9948 24.5918 25.9946 24.2664C25.9944 23.9409 25.8649 23.6288 25.6346 23.3988L18.2341 16.0004L25.6346 8.60204Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>

          <p className="mt-5 text-black font-inter lg:text-xl text-xs font-normal leading-normal">
            Sign up for <span className="font-bold">FREE</span> to unlock access!
          </p>
        </div>

        <div className="mt-10 lg:space-y-6 space-y-4">
        <div className="flex flex-col lg:flex-row items-center justify-between gap-4 w-full">

          {/* <div className="flex items-center justify-between"> */}
            <div className="flex flex-col space-y-2 w-full">
              <label className="text-[#0F0F0F] font-inter lg:text-xl text-xs font-normal">
                Name
              </label>
              <input
                id="name"
                name="name"
                className="rounded-[14px] border border-solid border-[#959595] py-3 px-5 font-inter lg:text-xl text-xs font-medium"
                type="text"
                placeholder="Name"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>

            <div className="flex flex-col space-y-2 w-full">
              <label className="text-[#0F0F0F] font-inter lg:text-xl text-xs font-normal">
                Surname
              </label>
              <input
                id="surname"
                name="surname"
                className="rounded-[14px] border border-solid border-[#959595] py-3 px-5 font-inter lg:text-xl text-xs font-medium"
                type="text"
                placeholder="Surname"
                value={surname}
                onChange={e => setSurname(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="text-[#0F0F0F] font-inter lg:text-xl text-xs font-normal">
              Email
            </label>
            <input
              id="email"
              name="email"
              className={`rounded-[14px] border border-solid py-3 px-5 font-inter lg:text-xl text-xs font-medium ${
                email.trim() !== '' && !validateEmail(email) ? 'border-red-500' : 'border-[#959595]'
              }`}
              type="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {email.trim() !== '' && !validateEmail(email) && (
              <span className="text-red-500 text-sm">Invalid email format</span>
            )}
          </div>

          <div className="flex flex-col space-y-2">
            <label className="text-[#0F0F0F] font-inter lg:text-xl text-xs font-normal">
              Password
            </label>
            <div className="relative">
              <input
                id="password"
                name="password"
                className={`rounded-[14px] border border-solid py-3 px-5 font-inter lg:text-xl text-xs font-medium w-full ${
                  password.trim() !== '' && passwordErrors.length > 0
                    ? 'border-red-500'
                    : 'border-[#959595]'
                }`}
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                )}
              </button>
            </div>

            {passwordErrors.length > 0 && (
              <div className="text-red-500 text-sm">
                <p>Password must contain:</p>
                <ul className="list-disc pl-5">
                  {passwordErrors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div>
            <Button
              size="medium"
              type="submit"
              color={isFormValid ? 'red' : 'gray'}
              label={<p className="text-white font-inter">Sign up</p>}
              isDisabled={!isFormValid}
              className="w-full"
            />
          </div>
          <p
            className="w-full text-center underline underline-offset-2 font-inter text-base font-bold cursor-pointer"
            onClick={() => navigate('/login')}
          >
            Login
          </p>
        </div>
      </form>
    </Modal>
  );
};
