import FeedbackSlider from './FeedbackSlider';

export default function Feedback() {
  return (
    <div className="feedback">
      <div className="container">
        <h2 className="title_2">OUR CLIENTS SAY</h2>

        <div className="feedback__wrap">
          <FeedbackSlider />
        </div>
      </div>
    </div>
  );
}
