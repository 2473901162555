import React from 'react';

import '../../assets/assets-wowsummit/css/reset.scss'
import '../../assets/assets-wowsummit/css/page.scss'
import '../../assets/assets-wowsummit/fonts/Inter/stylesheet.css'

import Whatsapp from './WowSummitComponents/Whatsapp';

import Main from './WowSummitComponents/Main';
import VipPackage from './WowSummitComponents/VipPackage';
import HotelsRecommend from './WowSummitComponents/HotelsRecommend';
import Footer from './WowSummitComponents/Footer';

export function WowSummit() {
  return (
    <div className="wowSummit wrapper">
      <Whatsapp />
      <Main />
      <VipPackage />
      <HotelsRecommend />
      <Footer />
    </div>
  );
}
