import { useLocation, useNavigate } from 'react-router-dom';
import { IconSvg } from '../IconSvg';
import { Button } from '../button/Button';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { MenuCard } from './MenuCard';
import LoginSignupModal from './modal/LoginSignupModal';
import { useHttp } from 'src/hooks';
import { Icons } from 'src/types/icons';
import { LoadingSpinner } from '../loading-spinner/LoadingSpinner';
import { signOut } from 'src/utils/cookieUtils';

interface Options {
  name: string;
  value: string;
  path: string;
  icon: Icons;
  active: boolean;
  activeIcon: Icons;
}
export function Header({ scrollToFaq, scrollToMP }: any) {
  const {
    fetchProfile,
    logout,
    loginWithRedirect,
    appConfig,
    userAuth0,
    accessToken,
    profile,
    isFetchingProfile,
    isLoadingAuth0,
    customLogoutHandler,
  } = useContext(AuthContext);
  const [isLoginSignupModalOpen, setIsLoginSignupModalOpen] = useState<boolean>(false);
  const { sendRequest: apiRequestHandler, error, isLoading } = useHttp();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setIsLoginSignupModalOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [JSON.stringify(window.innerWidth)]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const signupHandler = () => {
  //   navigate('/sign-up');
  //   return;
  //   void loginWithRedirect({
  //     appState: { returnTo: `/sign-up` },
  //     screen_hint: 'signup',
  //   });
  // };

  const onChangeMenuMember = (value: string, name: string, path: string) => {
    if (value == 'My trips') {
      window.open(path, '_blank');
      return;
    }
    if (value == 'Sign out') {
      // logout();
      // window.localStorage.clear()
      customLogoutHandler();
      return;
    }
    navigate(path);
  };

  const [myTripUrl, setMyTripUrl] = useState<string>('');

  const getTokenHandler = () => {
    apiRequestHandler(
      {
        url: `auth/generate-token`,
        accessToken,
        method: 'POST',
      },
      (response: any) => {
        const baseUrl = 'https://booking.xplorius.com';
        const token = encodeURIComponent(response?.accessToken);
        const navigateTo = encodeURIComponent('/mytrip/mytrips');
        const url = `${baseUrl}/sso?token=${token}&navigateTo=${navigateTo}`;
        setMyTripUrl(url);
      },
    );
  };

  useEffect(() => {
    accessToken && getTokenHandler();
  }, [accessToken]);

  const optionsData: Options[] = [
    {
      name: 'My profile',
      value: 'My profile',
      active: pathname == '/profile',
      icon: 'userBlack',
      activeIcon: 'userCopperRed',
      path: '/profile',
    },
    {
      name: 'Book now',
      value: 'Book now',
      active: pathname.includes('booking'),
      icon: 'bookNowBlack',
      activeIcon: 'bookNowCopperRed',
      path: '/booking',
    },
    {
      name: 'My trips',
      value: 'My trips',
      active: pathname.includes('trips'),
      icon: 'airplaneBlack',
      activeIcon: 'airplaneBlack',
      path: `${myTripUrl}`,
    },
    {
      name: 'Billing',
      value: 'Billing',
      active: pathname.includes('billing'),
      icon: 'creditCardBlack',
      activeIcon: 'creditCardCopperRed',
      path: '/billing',
    },
    {
      name: 'Help',
      value: 'Help',
      active: pathname.includes('help'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/help',
    },
    {
      name: 'Sign out',
      value: 'Sign out',
      active: false,
      icon: 'signOutBlack',
      activeIcon: 'signOutBlack',
      path: '',
    },
  ];
  if (appConfig?.isAdmin) {
    const newOption: Options = {
      name: 'Coupons',
      value: 'Coupons',
      active: pathname.includes('coupon'),
      icon: 'helpBlack',
      activeIcon: 'helpCopperRed',
      path: '/coupons',
    };
    optionsData.splice(optionsData.length - 1, 0, newOption);
  }

  return (
    <>
      <div className="lg:px-10 px-4 lg:border-b lg:border-[#4A4A4A] h-[81px]">
        <div className="flex items-center justify-between w-full h-full">
          <div className="flex items-start justify-center rounded">
            <IconSvg className="cursor-pointer" icon="logoHeader" onClick={() => navigate('/')} />
          </div>
          {pathname == '/' ? (
            <div className="lg:flex hidden font-inter font-light text-base text-[#4A4A4A] h-full items-center gap-[60px]">
              <p
                onClick={() => scrollToMP()}
                className="mt-2 cursor-pointer "
              >
                MEMBERSHIP & PRICING
              </p>
              <p
                onClick={() => scrollToFaq()}
                className="mt-2 cursor-pointer "
              >
                FAQ
              </p>
            </div>
          ) : (
            <div className="lg:flex hidden font-inter font-light text-base text-[#4A4A4A] h-full items-center gap-[60px]">
              <a href='#membership-pricing' style={{textDecoration:'none'}} className="hover:no-underline hover:text-[#0F0F0F] ">
              <p
                onClick={() => (navigate('/'))}
                className="mt-2 cursor-pointer "
              >
                MEMBERSHIP & PRICING
              </p>
              </a>
              <a href='#faq' style={{textDecoration:'none'}} className="hover:no-underline hover:text-[#0F0F0F] ">
              <p
                onClick={() => (navigate('/'))}
                className="mt-2 cursor-pointer "
              >
                FAQ
              </p>
              </a>
            </div>
          )}
          {accessToken && profile?.firstName ? (
            <div className="lg:block hidden">
              <MenuCard
                onChange={(value: string, name: string, path: string) =>
                  onChangeMenuMember(value, name, path)
                }
                className="w-[137px]"
                options={optionsData}
                placeholder={`${
                  profile?.firstName
                    ? profile?.firstName?.includes('@')
                      ? profile?.firstName?.split('@')[0]?.length > 5
                        ? profile?.firstName?.split('@')[0]?.slice(0, 5) + '...'
                        : profile?.firstName?.split('@')[0]
                      : profile?.firstName.length > 5
                      ? profile?.firstName.slice(0, 5) + '...'
                      : profile?.firstName
                    : profile?.email?.split('@')[0]?.length > 5
                    ? profile?.email?.split('@')[0]?.slice(0, 5) + '...'
                    : profile?.email?.split('@')[0]
                }`}
              />
            </div>
          ) : (
            <div className="lg:block hidden">
              <div className="flex items-center gap-8 ">
                <p
                  className="cursor-pointer font-inter font-light text-base leading-[19px]"
                  onClick={() => navigate('/login')}
                >
                  LOGIN
                </p>
                {/* <Button
                  className="font-medium px-14"
                  color="yellow"
                  size="extra-small"
                  label={'SIGN UP'}
                  onClick={() => signupHandler()}
                /> */}
              </div>
            </div>
          )}

          {accessToken && profile?.firstName && (
            <div className="lg:hidden block ">
              <MenuCard
                onChange={(value: string, name: string, path: string) =>
                  onChangeMenuMember(value, name, path)
                }
                className="w-[137px]"
                options={optionsData}
                placeholder={`${profile?.firstName}`}
                placeHolderIcon={
                  <div className="h-[25px] w-[25px] bg-[#C56B48] rounded-full">
                    <IconSvg icon="userCream" className="h-full w-full" />
                  </div>
                }
                icon={true}
              />
            </div>
          )}

          {!accessToken && (
            <div className="lg:hidden block">
              <IconSvg icon="hamburger" onClick={() => setIsLoginSignupModalOpen(true)} />
            </div>
          )}
        </div>
      </div>
      <LoginSignupModal
        onSubmit={(obj: any) => console.log(obj)}
        isOpen={isLoginSignupModalOpen}
        onCancel={() => setIsLoginSignupModalOpen(false)}
        isPreview={true}
      />
      {(isFetchingProfile || isLoadingAuth0) && <LoadingSpinner />}
    </>
  );
}
