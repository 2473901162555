
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';

import m1 from '../../../assets/assets-homepage/img/m1.webp';
import m2 from '../../../assets/assets-homepage/img/m2.webp';
import m3 from '../../../assets/assets-homepage/img/m3.webp';
import m4 from '../../../assets/assets-homepage/img/m4.webp';
import m5 from '../../../assets/assets-homepage/img/m5.webp';
import m6 from '../../../assets/assets-homepage/img/m6.webp';
import m7 from '../../../assets/assets-homepage/img/m7.webp';
import m8 from '../../../assets/assets-homepage/img/m8.webp';
import m9 from '../../../assets/assets-homepage/img/m9.webp';
import m10 from '../../../assets/assets-homepage/img/m10.webp';
import m11 from '../../../assets/assets-homepage/img/m11.webp';
import m12 from '../../../assets/assets-homepage/img/m12.webp';

const MashaSlider = () => {
  const images = [m1, m2, m3, m4, m5, m6, m7, m8, m9, m10, m11, m12];
  const instagramLink = "https://www.instagram.com/p/DCXOsLdiF9c/?img_index=1";

  return (
    <div className="masha">
      <div className="container">
        <h2 className="title_2">MASHA LOBANOVA x XPLORIUS</h2>
        <Swiper
          modules={[Pagination, Autoplay, FreeMode]}
          freeMode={true}
          loop={true}
          speed={3000}
          autoplay={{ delay: 300, disableOnInteraction: false }}
          pagination={{ clickable: true, el: '.masha_pagination' }}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 11,
              loopAdditionalSlides: 2,
              speed: 500,
              initialSlide: 1,
              autoplay: false,
              freeMode: false,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 22,
              loopAdditionalSlides: 2,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 38,
            },
          }}
          className="masha_slider"
        >
          {images.map((img, index) => (
            <SwiperSlide key={index}>
              <a href={instagramLink} className="masha_slide_img">
                <img src={img} alt="Masha Lobanova" />
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="pagination masha_pagination"></div>
      </div>
    </div>
  );
};

export default MashaSlider;
