import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';

import amountDollar from '../../../assets/assets-homepage/img/icons/lsicon_amount-dollar-outline.svg';
import handDeposit from '../../../assets/assets-homepage/img/icons/ph_hand-deposit.svg';
import usersFour from '../../../assets/assets-homepage/img/icons/ph_users-four.svg';

const BecomeClient = () => {
  const slides = [
    { img: amountDollar, text: 'Book a trip worth a minimum of $10,000' },
    { img: handDeposit, text: 'Deposit $10,000 in hotel booking credit (T&Cs apply)' },
    { img: usersFour, text: 'By Referral' },
  ];

  return (
    <div className="become" id="become">
      <div className="container">
        <h2 className="title_2">how to become a client</h2>
        <Swiper
          modules={[Pagination]}
          pagination={{ clickable: true, el: '.become_pagination', }}
          breakpoints={{
            0: { slidesPerView: 1, spaceBetween: 41 },
            640: { slidesPerView: 1, spaceBetween: 83 },
            992: { slidesPerView: 3, spaceBetween: 43 },
            1200: { slidesPerView: 3, spaceBetween: 83 },
          }}
          className="become_slider"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="become_item_wrp">
                <div className="become_item">
                  <div className="become_item__img">
                    <img src={slide.img} alt="" />
                  </div>
                  <h6 className="become_item__title">{slide.text}</h6>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="pagination become_pagination"></div>
        </Swiper>
        <p className="become_p">CRAFTED FOR THE FEW, NOT THE MANY.</p>
      </div>
    </div>
  );
};

export default BecomeClient;
