import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import star from '../../../assets/assets-homepage/img/icons/Star3.svg';
import usr1 from '../../../assets/assets-homepage/img/usr1.webp';
import usr2 from '../../../assets/assets-homepage/img/usr2.webp';
import usr3 from '../../../assets/assets-homepage/img/usr3.webp';
import usr4 from '../../../assets/assets-homepage/img/usr4.webp';
import usr5 from '../../../assets/assets-homepage/img/usr5.webp';
import usr6 from '../../../assets/assets-homepage/img/usr6.webp';
import usr7 from '../../../assets/assets-homepage/img/usr7.webp';
import usr8 from '../../../assets/assets-homepage/img/usr8.webp';
 import quote from '../../../assets/assets-homepage/img/icons/quote.svg';

const FeedbackSlider = () => {
  const testimonials = [
    {
      id: 1,
      text: "I especially want to highlight their customer service team, very professional. The best rates, VIP treatment, and a stress-free booking experience.",
      name: "Dinara Bakhtiyarova",
      image: usr1
    },
    {
      id: 2,
      text: "With XPLORIUS, I can book anytime, anywhere, paying with crypto and saving a lot on top of it!",
      name: "Sanjay Saini",
      image: usr2
    },
    {
      id: 3,
      text: "I keep coming back to XPLORIUS. Everything is seamless, and the hotels are exceptional!",
      name: "Emiliya",
      image: usr3
    },
    {
      id: 4,
      text: "Lisbon with XPLORIUS was incredible. Booked a boutique hotel and even paid in crypto!",
      name: "Rahim Mehtab",
      image: usr4
    },
    {
      id: 5,
      text: "Ibiza was a great experience, everything was handled well, and the experiences were top notch, with amazing people.",
      name: "GMoney",
      image: usr5
    },
    {
      id: 6,
      text: "Our family trip to Italy & France was flawless. The process was simple, and the hotels were top-tier.",
      name: "Kristina Gusarova",
      image: usr6
    },
    {
      id: 7,
      text: "The team is dedicated to providing the special off market deals whilst also keeping the experience of very high quality!",
      name: "Elisa",
      image: usr7
    },
    {
      id: 8,
      text: "XPLORIUS put together my dream Bali getaway—top hotels, exclusive packages, and seamless planning from start to finish. Cannot wait for the new adventures!",
      name: "Anna Bulgakova",
      image: usr8
    }
  ];

  return (
    <>
      <Swiper
        spaceBetween={20}
        freeMode={true}
        speed={2000}
        loop={true}
        modules={[FreeMode, Autoplay, Pagination]}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false
        }}
        pagination={{
          el: '.feedback_pagination',
          clickable: true
        }}
        breakpoints={{
          0: {
            slidesPerView: 'auto',
            centeredSlides: false,
            spaceBetween: 12,
            autoplay: false,
            freeMode: true,
            speed: 400
          },
          640: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            centeredSlides: true
          },
          992: {
            centeredSlides: false,
            spaceBetween: 20,
            slidesPerView: 2
          },
          1380: {
            slidesPerView: 3,
            loopAdditionalSlides: 1
          }
        }}
        onSlideChange={(swiper: any) => {
          if (swiper.isBeginning || swiper.isEnd) {
            swiper.loopFix();
          }
        }}
        className="feedback_slider"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="feedback_item">
              <div className="feedback_item__top">
                <img src={quote} alt="Quote" />
                <p>{testimonial.text}</p>
              </div>

              <div className="feedback_item__bottom">
                <div className="feedback_item__user">
                  <div className="feedback_item__img">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>

                  <div className="feedback_item__user_info">
                    <div className="feedback_item__rating flex items-center space-x-1">
                      {[...Array(5)].map((_, i) => (
                        <img key={i} src={star} alt="Star" />
                      ))}
                    </div>
                    <span>{testimonial.name}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="pagination feedback_pagination"></div>
    </>
  );
};

export default FeedbackSlider;