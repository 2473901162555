
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';

import "swiper/css";
import "swiper/css/pagination";

import choo1 from "../../../assets/assets-homepage/img/choo1.webp";
import choo2 from "../../../assets/assets-homepage/img/choo2.webp";
import choo3 from "../../../assets/assets-homepage/img/choo3.webp";
import choo4 from "../../../assets/assets-homepage/img/choo4.webp";
import choo5 from "../../../assets/assets-homepage/img/choo5.webp";
import choo6 from "../../../assets/assets-homepage/img/choo6.webp";
import choo7 from "../../../assets/assets-homepage/img/choo7.webp";
import choo8 from "../../../assets/assets-homepage/img/choo8.webp";
import choo9 from "../../../assets/assets-homepage/img/choo9.webp";
import choo10 from "../../../assets/assets-homepage/img/choo10.webp";
import choo11 from "../../../assets/assets-homepage/img/choo11.webp";

const clients = [
  { img: choo1, label: "Jumeirah Burj Al Arab" },
  { img: choo2, label: "JOALI Being" },
  { img: choo3, label: "Soneva Jani" },
  { img: choo4, label: "Cheval Blanc Seychelles" },
  { img: choo5, label: "The Lana - Dorchester Collection" },
  { img: choo6, label: "Castello di Reschio" },
  { img: choo7, label: "Mandarin Oriental, Singapore" },
  { img: choo8, label: "Le Meurice – Dorchester Collection" },
  { img: choo9, label: "Nobu Hotel Ibiza Bay" },
  { img: choo10, label: "Les Airelles, Courchevel" },
  { img: choo11, label: "Carlton Cannes, a Regent Hotel" }
];

const ClientsChooseSlider = () => {
  const swiperRef = useRef<any | null>(null);


  useEffect(() => {
    const handleClick = (e: any) => {
      if (!swiperRef.current) return;
      const swiper = swiperRef.current.swiper;
      const clickedSlide = e.target.closest(".swiper-slide");

      if (!clickedSlide) return;

      if (
        clickedSlide.classList.contains("swiper-slide-next") ||
        clickedSlide.classList.contains("swiper-slide-active")
      ) {
        swiper.slideNext();
      } else if (clickedSlide.classList.contains("swiper-slide-prev")) {
        swiper.slidePrev();
      }
    };

    const sliderElement = swiperRef.current;
    if (sliderElement) {
      sliderElement.addEventListener("click", handleClick);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <div className="clients_choose">
      <div className="container">
        <h2 className="title_2">OUR CLIENTS CHOOSE</h2>
        <Swiper
          ref={swiperRef}
          modules={[Pagination]}
          loop={true}
          slidesPerView={3}
          centeredSlides={true}
          pagination={{ clickable: true, el: ".clients_choose_pagination" }}
          speed={0}
          breakpoints={{
            0: { slidesPerView: 1, loop: true },
            640: { slidesPerView: 1, centeredSlides: false, loop: true },
            992: { slidesPerView: 3, centeredSlides: true, loop: true }
          }}
          className="clients_choose_slider"
        >
          {clients.map((client, index) => (
            <SwiperSlide key={index}>
              <div className="clients_choose_item">
                <div className="clients_choose_item__img">
                  <img src={client.img} alt={client.label} />
                </div>
                <div className="clients_choose_item__label">
                  <span>{client.label}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="pagination clients_choose_pagination"></div>
        </Swiper>
      </div>
    </div>
  );
};

export default ClientsChooseSlider;