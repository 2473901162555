import { ReactNode, useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, IconSvg } from 'src/components/common';
import { InputText } from 'src/components/common/form';
import { useNavigate } from 'react-router-dom';
import { setToLocalStorage } from '../../../utils';
import { useHttp, useNotification } from '../../../hooks';
import { AuthContext } from '../../../contexts';
import { Icons } from 'src/types/icons';
import GoogleLoginButton from 'src/components/common/ui/button/GoogleLoginButton';

export interface LoginForm {
  email: string;
  password: string;
}
const passwordRules =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      passwordRules,
      'Password must be at least 8 characters long and contain at least 3 of the following: lowercase letters, uppercase letters, numbers, and special characters',
    ),
});

export function Login() {
  const navigate = useNavigate();
  const notify = useNotification();
  const { accessToken, setAccessToken } = useContext(AuthContext);
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema,
    onSubmit: values => {
      console.log('there', values);
      login(values);
    },
  });
  const { values, errors, setFieldValue, handleSubmit, setValues, touched } = formik;
  const onChangeHandler = ({ name, value }: { name: string; value: string }) => {
    setFieldValue(name, value);
  };

  const formInputs = [
    {
      name: 'email',
      label: '',
      placeholder: 'Email',
      disabled: false,
      textArea: false,
      multiSelect: false,
    },
    {
      name: 'password',
      label: '',
      placeholder: 'Password',
      type: 'password',
      disabled: false,
      textArea: false,
      multiSelect: false,
      icon: 'eye' as Icons,
    },
  ];
  const { sendRequest: apiRequestHandler, isLoading: isLoading, error } = useHttp();
  const login = (payload: LoginForm) => {
    apiRequestHandler(
      {
        method: 'POST',
        url: `auth/login`,
        accessToken,
        payload: {
          email: payload.email,
          password: payload.password,
        },
      },
      (data: any) => {
        setToLocalStorage(`::@@xploriusJwtAuthspajs@@::`, data);
        setAccessToken(data.accessToken);
        navigate('/booking');
      },
    );
  };

  useEffect(() => {
    if (error) {
      notify(error || '', 'error');
    }
  }, [error]);

  return (
    <div
      className=" bg-cover bg-center h-full w-full lg:bg-auth-background sm:bg-auth-background-small bg-none flex justify-center items-center"
    >
      <div className='lg:w-[480px] sm:w-[448px] sm:h-auto h-full w-full bg-[#EFE8DF] rounded-[5px] sm:p-6 p-2.5'>
        <form onSubmit={handleSubmit}>
          <div className="pb-4 px-1.5">
            <>
              <div className="flex flex-col justify-center items-center">
                <IconSvg
                  icon="logoBlack"
                  className="w-[148.78px] h-[36.16px] cursor-pointer"
                  onClick={() => navigate('/')}
                />
                <div className="mt-10 mb-[30px]">
                  <p className="font-bold text-xl text-[#0F0F0F] font-inter leading-6">
                    Hello, who’s this?
                  </p>
                  <p className="font-light font-inter text-base leading-5 text-center">
                    Login to XPLORIUS.
                  </p>
                </div>
              </div>

              {formInputs.map((sub, i) => (
                <div className={`${i + 1 == formInputs.length ? 'mt-5' : ''}`} key={i}>
                  <InputText
                    id={sub.name}
                    name={sub.name}
                    label={sub.label}
                    placeholder={sub.placeholder}
                    type={sub?.type}
                    value={values[sub.name as keyof LoginForm]}
                    onChange={onChangeHandler}
                    isDisabled={sub.disabled}
                    icon={sub?.icon}
                    // wrapperStyles={styles['field-wrapper']}
                    isError={
                      touched[sub.name as keyof LoginForm] && !!errors[sub.name as keyof LoginForm]
                    }
                    errorMessage={errors[sub.name as keyof LoginForm]}
                  />
                </div>
              ))}
              <div className="flex items-center justify-between sm:mt-5 mt-4  sm:mb-5 mb-[30px] ">
                <p
                  className="font-medium font-inter border-b inline-block border-[#4A4A4A] text-[#4A4A4A] text-sm leading-[16.8px] cursor-pointer"
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot password?
                </p>
                {/* <p
                  className="font-medium font-inter  text-base cursor-pointer"
                  onClick={() => navigate('/sign-up')}
                >
                  Sign up
                </p> */}
              </div>
              <div className="">
                <Button
                  size="medium"
                  type="submit"
                  color="yellow"
                  label={
                    <p className="text-[#0F0F0F] font-medium text-base font-inter ">LOGIN</p>
                  }
                  className="w-full"
                />
              </div>
              <div className="flex items-center w-full sm:my-5 my-[15px]">
                <div className="w-full border-t border-[#959595]"></div>
                <p className="text-[#959595] font-medium font-inter text-lg px-4">or</p>
                <div className="w-full border-t border-[#959595]"></div>
              </div>

              <div className="border rounded-[5px] border-[#4A4A4A] py-2 flex justify-center items-center gap-4 bg-[#4A4A4A]">
                <GoogleLoginButton label={'LOGIN WITH GOOGLE'}  type={'login'} />
                {/* <IconSvg icon="google" />
                <p className="text-[#EFE8DF] text-base font-medium font-inter leading-[19.2px]">
                  LOGIN WITH GOOGLE
                </p> */}
              </div>

              {/* <p
                className="font-light font-inter  text-base cursor-pointer text-center sm:mt-5 mt-6 leading-[19.2px]"
                onClick={() => navigate('/sign-up')}
              >
                Don’t have an account
                <span className="font-medium font-inter">? Signup</span>
              </p> */}
            </>
          </div>
        </form>
      </div>
    </div>
  );
}
