import logofoo from "../../../assets/assets-wowsummit/img/logofoo.svg"
import instagram from "../../../assets/assets-wowsummit/img/icons/mdi_instagram.svg"
import twitter from "../../../assets/assets-wowsummit/img/icons/basil_twitter-outline.svg"

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <a href="/" className="footer__logo">
          <img src={logofoo} />
        </a>

        <div className="footer__contact">
          <p>
            Email: <a href="mailto:hi@xplorius.com">hi@xplorius.com</a>
          </p>
          <p>
            WhatsApp:{' '}
            <a href="https://wa.me/message/6GFBB37QHD6UN1">+971 58 565 7715</a>
          </p>
        </div>

        <div className="footer__social">
          <a href="https://www.instagram.com/travelwithxplorius/">
            <img src={instagram} />
          </a>
          <a href="https://www.tiktok.com/@travelwithxplorius">
            <img src={twitter} />
          </a>
        </div>

        <a href="https://xplorius.com/login" className="login_btn">
          Login
        </a>
      </div>
    </div>
  );
}
